/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPGTransaction = /* GraphQL */ `
  query GetPGTransaction($id: ID!) {
    getPGTransaction(id: $id) {
      id
      order_id
      tracking_id
      bank_ref_no
      order_status
      failure_message
      payment_mode
      card_name
      status_code
      status_message
      currency
      amount
      billing_name
      billing_address
      billing_city
      billing_state
      billing_zip
      billing_country
      billing_tel
      billing_email
      billing_notes
      delivery_name
      delivery_address
      delivery_city
      delivery_state
      delivery_zip
      delivery_country
      delivery_tel
      merchant_param1
      merchant_param2
      merchant_param3
      merchant_param4
      merchant_param5
      vault
      offer_type
      offer_code
      discount_value
      mer_amount
      eci_value
      retry
      response_code
      si_created
      si_status
      si_ref_no
      si_sub_ref_no
      si_mer_ref_no
      si_error_desc
      bene_account
      bene_name
      bene_ifsc
      bene_bank
      bene_branch
      inv_mer_reference_no
      trans_date
      trans_fee
      service_tax
      bin_supported
      bin_country
      customer_card_id
      Cart {
        id
        status
        total_amount
        ProposerUser {
          id
          fullName
          mobile
          email
          pan
          addressLine1
          addressLine2
          city
          state
          pincode
          gstin
          countryCode
          addressType
          createdAt
          updatedAt
        }
        ProposerVet {
          id
          vet_name
          vet_id
          createdAt
          updatedAt
        }
        CartItems {
          nextToken
        }
        tncagreed
        createdAt
        updatedAt
        cartProposerUserId
        cartProposerVetId
      }
      createdAt
      updatedAt
      pGTransactionCartId
    }
  }
`;
export const listPGTransactions = /* GraphQL */ `
  query ListPGTransactions(
    $filter: ModelPGTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPGTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order_id
        tracking_id
        bank_ref_no
        order_status
        failure_message
        payment_mode
        card_name
        status_code
        status_message
        currency
        amount
        billing_name
        billing_address
        billing_city
        billing_state
        billing_zip
        billing_country
        billing_tel
        billing_email
        billing_notes
        delivery_name
        delivery_address
        delivery_city
        delivery_state
        delivery_zip
        delivery_country
        delivery_tel
        merchant_param1
        merchant_param2
        merchant_param3
        merchant_param4
        merchant_param5
        vault
        offer_type
        offer_code
        discount_value
        mer_amount
        eci_value
        retry
        response_code
        si_created
        si_status
        si_ref_no
        si_sub_ref_no
        si_mer_ref_no
        si_error_desc
        bene_account
        bene_name
        bene_ifsc
        bene_bank
        bene_branch
        inv_mer_reference_no
        trans_date
        trans_fee
        service_tax
        bin_supported
        bin_country
        customer_card_id
        Cart {
          id
          status
          total_amount
          tncagreed
          createdAt
          updatedAt
          cartProposerUserId
          cartProposerVetId
        }
        createdAt
        updatedAt
        pGTransactionCartId
      }
      nextToken
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      status
      total_amount
      ProposerUser {
        id
        fullName
        mobile
        email
        pan
        addressLine1
        addressLine2
        city
        state
        pincode
        gstin
        geoCordinates {
          lat
          log
        }
        countryCode
        addressType
        createdAt
        updatedAt
      }
      ProposerVet {
        id
        vet_name
        vet_id
        createdAt
        updatedAt
      }
      CartItems {
        items {
          id
          age
          breed_group
          breed
          premium_excl_gst
          cgst
          sgst
          igst
          microchip
          microchip_excl_gst
          plan
          sum_insured
          quantity
          cartID
          createdAt
          updatedAt
        }
        nextToken
      }
      tncagreed
      createdAt
      updatedAt
      cartProposerUserId
      cartProposerVetId
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        total_amount
        ProposerUser {
          id
          fullName
          mobile
          email
          pan
          addressLine1
          addressLine2
          city
          state
          pincode
          gstin
          countryCode
          addressType
          createdAt
          updatedAt
        }
        ProposerVet {
          id
          vet_name
          vet_id
          createdAt
          updatedAt
        }
        CartItems {
          nextToken
        }
        tncagreed
        createdAt
        updatedAt
        cartProposerUserId
        cartProposerVetId
      }
      nextToken
    }
  }
`;
export const getCartItems = /* GraphQL */ `
  query GetCartItems($id: ID!) {
    getCartItems(id: $id) {
      id
      age
      breed_group
      breed
      premium_excl_gst
      cgst
      sgst
      igst
      microchip
      microchip_excl_gst
      plan
      sum_insured
      quantity
      cartID
      createdAt
      updatedAt
    }
  }
`;
export const listCartItems = /* GraphQL */ `
  query ListCartItems(
    $filter: ModelCartItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCartItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        age
        breed_group
        breed
        premium_excl_gst
        cgst
        sgst
        igst
        microchip
        microchip_excl_gst
        plan
        sum_insured
        quantity
        cartID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cartItemsByCartID = /* GraphQL */ `
  query CartItemsByCartID(
    $cartID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCartItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartItemsByCartID(
      cartID: $cartID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        age
        breed_group
        breed
        premium_excl_gst
        cgst
        sgst
        igst
        microchip
        microchip_excl_gst
        plan
        sum_insured
        quantity
        cartID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVets = /* GraphQL */ `
  query GetVets($id: ID!) {
    getVets(id: $id) {
      id
      name
      name_lc
      clinic_name
      vet_reg_no
      mobile
      email
      address
      createdAt
      updatedAt
    }
  }
`;
export const listVets = /* GraphQL */ `
  query ListVets(
    $filter: ModelVetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        name_lc
        clinic_name
        vet_reg_no
        mobile
        email
        address
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProposerVet = /* GraphQL */ `
  query GetProposerVet($id: ID!) {
    getProposerVet(id: $id) {
      id
      vet_name
      vet_id
      createdAt
      updatedAt
    }
  }
`;
export const listProposerVets = /* GraphQL */ `
  query ListProposerVets(
    $filter: ModelProposerVetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProposerVets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vet_name
        vet_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPremiumChart = /* GraphQL */ `
  query GetPremiumChart($id: ID!) {
    getPremiumChart(id: $id) {
      id
      plan
      breed_group
      sum_insured
      age_bracket
      premium_excl_gst
      premium_incl_gst
      createdAt
      updatedAt
    }
  }
`;
export const listPremiumCharts = /* GraphQL */ `
  query ListPremiumCharts(
    $filter: ModelPremiumChartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPremiumCharts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        plan
        breed_group
        sum_insured
        age_bracket
        premium_excl_gst
        premium_incl_gst
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBreed = /* GraphQL */ `
  query GetBreed($id: ID!) {
    getBreed(id: $id) {
      id
      group
      label
      lc_label
      createdAt
      updatedAt
    }
  }
`;
export const listBreeds = /* GraphQL */ `
  query ListBreeds(
    $filter: ModelBreedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        label
        lc_label
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProposerUser = /* GraphQL */ `
  query GetProposerUser($id: ID!) {
    getProposerUser(id: $id) {
      id
      fullName
      mobile
      email
      pan
      addressLine1
      addressLine2
      city
      state
      pincode
      gstin
      geoCordinates {
        lat
        log
      }
      countryCode
      addressType
      createdAt
      updatedAt
    }
  }
`;
export const listProposerUsers = /* GraphQL */ `
  query ListProposerUsers(
    $filter: ModelProposerUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProposerUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        mobile
        email
        pan
        addressLine1
        addressLine2
        city
        state
        pincode
        gstin
        geoCordinates {
          lat
          log
        }
        countryCode
        addressType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
