import { useState } from "react";
import { API ,graphqlOperation} from 'aws-amplify';
import { listBreeds } from '../graphql/queries';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';

import {
    View,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
const AsyncTypeahead = withAsync(Typeahead);


export default function BreedSearch({ onChange, defaultInputValue, disabled }) {

    const [items, setItems] = useState([]);
    const [lastquery, setLastQuery] = useState('');



    async function searchBreeds(query) {
        let breeds = [];
        if (query && query !== '') {
            //console.log(query);
            let filter = { lc_label: { contains: query?.toLowerCase() } }
            let response = await API.graphql(graphqlOperation(listBreeds, {filter: filter , limit:1000}));
            breeds = response?.data?.listBreeds?.items;
            setLastQuery(query);
            //console.log(breeds);
            setItems(breeds);
        } else {
            let response = await API.graphql(graphqlOperation(listBreeds));
            breeds = response?.data?.listBreeds?.items;
            setItems(breeds);
        }
        return breeds;
    }

   
    return (
        <View>
            <AsyncTypeahead 
                id="get-quote-typeahead"
                onChange={onChange}
                isLoading={true}
                onSearch={(query) => searchBreeds(query)}
                options={items}
                useCache={true}
                onFocus={(event) => searchBreeds(event?.target?.value)}
                promptText={'Type in 3 characters of breed'}
                emptyLabel={'No matching breed'}
                defaultInputValue={defaultInputValue ? defaultInputValue: lastquery}
                disabled={disabled}
                ignoreDiacritics={true}
                flip={true}
                //autoFocus={true}
            />
        </View>
    );

}

