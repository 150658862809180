import React, { useState ,useEffect} from 'react';
import {
  Grid,
  Button,
} from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function Footer(props) {
  const [showTNCDialog, setShowTNCDialog] = useState(false);
  const [showPPDialog, setShowPPDialog] = useState(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    if (showTNCDialog || showPPDialog) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = "scroll";
    }
}, [showTNCDialog,showPPDialog])

  function closeDialog() {
    setShowTNCDialog(false);
    setShowPPDialog(false);
    //openDialog(dispatch, false);
  }

  function handleShowTNC() {
    setShowTNCDialog(true);
    setShowPPDialog(false);
    //openDialog(dispatch, true);
  }

  function handleShowPP() {
    setShowTNCDialog(false);
    setShowPPDialog(true);
    //openDialog(dispatch, true);
  }

  const dialogStyle = {
    overlay: {zIndex: 1000},
    content: {
      border: '0',
      borderRadius: '4px',
      bottom: 'auto',
      //height: '600px',  // set height
      left: '1%',
      padding: '2rem',
      //position: 'fixed',
      right: 'auto',
      top: '1%', // start from center
      //transform: 'translate(-50%,-' + offsetPx + ')', // adjust top "up" based on height
      width: '80%',
      //maxWidth: '40rem'
      //overflow: 'hidden'
      
    }
  };


  return (
    <div>
      {/*FOOTER*/}
      <div className="copyright">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-auto mr-lg-auto">
              © 2023 Vetina Healthcare LLP
            </div>
            <div className="col-auto mr-lg-auto">
              Call us:  07506562266 / +91 20 67445858 Email: customerservice@pawtectindia.com
            </div>
            <div className="col-auto mr-lg-auto">
              <a className="col-auto mr-lg-auto" href={"#"} onClick={handleShowPP}>Privacy Policy</a>
            </div>
            <div className="col-auto mr-lg-auto">
              <a className="col-auto mr-lg-auto" href={"#"} onClick={handleShowTNC}>Term & Conditions</a>
            </div>
          </div>
        </div>
      </div>
      {/*/FOOTER*/}
      <Modal
        isOpen={showTNCDialog || showPPDialog}
        style={dialogStyle}
      >
        <Grid rowGap={'20px'} alignItems={'end'}>
          <Button
            variation="primary"
            width={'150px'}
            style={{ 'justifySelf': 'end' ,background:'#20bf6b'}}
            onClick={closeDialog}
          >
            Close
          </Button>
          <div style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '500px',
          }}>
            <Viewer
              fileUrl={showTNCDialog ? "pdf/Pawtect_TNC.pdf" : "pdf/Pawtect_PrivacyPolicy.pdf"}
              plugins={[defaultLayoutPluginInstance]}
            //height={'400px'}
            //scrollMode={ScrollMode.Page}
            />
          </div>
        </Grid>
      </Modal>
    </div>
  );
};

