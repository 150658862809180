/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPGTransaction = /* GraphQL */ `
  mutation CreatePGTransaction(
    $input: CreatePGTransactionInput!
    $condition: ModelPGTransactionConditionInput
  ) {
    createPGTransaction(input: $input, condition: $condition) {
      id
      order_id
      tracking_id
      bank_ref_no
      order_status
      failure_message
      payment_mode
      card_name
      status_code
      status_message
      currency
      amount
      billing_name
      billing_address
      billing_city
      billing_state
      billing_zip
      billing_country
      billing_tel
      billing_email
      billing_notes
      delivery_name
      delivery_address
      delivery_city
      delivery_state
      delivery_zip
      delivery_country
      delivery_tel
      merchant_param1
      merchant_param2
      merchant_param3
      merchant_param4
      merchant_param5
      vault
      offer_type
      offer_code
      discount_value
      mer_amount
      eci_value
      retry
      response_code
      si_created
      si_status
      si_ref_no
      si_sub_ref_no
      si_mer_ref_no
      si_error_desc
      bene_account
      bene_name
      bene_ifsc
      bene_bank
      bene_branch
      inv_mer_reference_no
      trans_date
      trans_fee
      service_tax
      bin_supported
      bin_country
      customer_card_id
      Cart {
        id
        status
        total_amount
        ProposerUser {
          id
          fullName
          mobile
          email
          pan
          addressLine1
          addressLine2
          city
          state
          pincode
          gstin
          countryCode
          addressType
          createdAt
          updatedAt
        }
        ProposerVet {
          id
          vet_name
          vet_id
          createdAt
          updatedAt
        }
        CartItems {
          nextToken
        }
        tncagreed
        createdAt
        updatedAt
        cartProposerUserId
        cartProposerVetId
      }
      createdAt
      updatedAt
      pGTransactionCartId
    }
  }
`;
export const updatePGTransaction = /* GraphQL */ `
  mutation UpdatePGTransaction(
    $input: UpdatePGTransactionInput!
    $condition: ModelPGTransactionConditionInput
  ) {
    updatePGTransaction(input: $input, condition: $condition) {
      id
      order_id
      tracking_id
      bank_ref_no
      order_status
      failure_message
      payment_mode
      card_name
      status_code
      status_message
      currency
      amount
      billing_name
      billing_address
      billing_city
      billing_state
      billing_zip
      billing_country
      billing_tel
      billing_email
      billing_notes
      delivery_name
      delivery_address
      delivery_city
      delivery_state
      delivery_zip
      delivery_country
      delivery_tel
      merchant_param1
      merchant_param2
      merchant_param3
      merchant_param4
      merchant_param5
      vault
      offer_type
      offer_code
      discount_value
      mer_amount
      eci_value
      retry
      response_code
      si_created
      si_status
      si_ref_no
      si_sub_ref_no
      si_mer_ref_no
      si_error_desc
      bene_account
      bene_name
      bene_ifsc
      bene_bank
      bene_branch
      inv_mer_reference_no
      trans_date
      trans_fee
      service_tax
      bin_supported
      bin_country
      customer_card_id
      Cart {
        id
        status
        total_amount
        ProposerUser {
          id
          fullName
          mobile
          email
          pan
          addressLine1
          addressLine2
          city
          state
          pincode
          gstin
          countryCode
          addressType
          createdAt
          updatedAt
        }
        ProposerVet {
          id
          vet_name
          vet_id
          createdAt
          updatedAt
        }
        CartItems {
          nextToken
        }
        tncagreed
        createdAt
        updatedAt
        cartProposerUserId
        cartProposerVetId
      }
      createdAt
      updatedAt
      pGTransactionCartId
    }
  }
`;
export const deletePGTransaction = /* GraphQL */ `
  mutation DeletePGTransaction(
    $input: DeletePGTransactionInput!
    $condition: ModelPGTransactionConditionInput
  ) {
    deletePGTransaction(input: $input, condition: $condition) {
      id
      order_id
      tracking_id
      bank_ref_no
      order_status
      failure_message
      payment_mode
      card_name
      status_code
      status_message
      currency
      amount
      billing_name
      billing_address
      billing_city
      billing_state
      billing_zip
      billing_country
      billing_tel
      billing_email
      billing_notes
      delivery_name
      delivery_address
      delivery_city
      delivery_state
      delivery_zip
      delivery_country
      delivery_tel
      merchant_param1
      merchant_param2
      merchant_param3
      merchant_param4
      merchant_param5
      vault
      offer_type
      offer_code
      discount_value
      mer_amount
      eci_value
      retry
      response_code
      si_created
      si_status
      si_ref_no
      si_sub_ref_no
      si_mer_ref_no
      si_error_desc
      bene_account
      bene_name
      bene_ifsc
      bene_bank
      bene_branch
      inv_mer_reference_no
      trans_date
      trans_fee
      service_tax
      bin_supported
      bin_country
      customer_card_id
      Cart {
        id
        status
        total_amount
        ProposerUser {
          id
          fullName
          mobile
          email
          pan
          addressLine1
          addressLine2
          city
          state
          pincode
          gstin
          countryCode
          addressType
          createdAt
          updatedAt
        }
        ProposerVet {
          id
          vet_name
          vet_id
          createdAt
          updatedAt
        }
        CartItems {
          nextToken
        }
        tncagreed
        createdAt
        updatedAt
        cartProposerUserId
        cartProposerVetId
      }
      createdAt
      updatedAt
      pGTransactionCartId
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      status
      total_amount
      ProposerUser {
        id
        fullName
        mobile
        email
        pan
        addressLine1
        addressLine2
        city
        state
        pincode
        gstin
        geoCordinates {
          lat
          log
        }
        countryCode
        addressType
        createdAt
        updatedAt
      }
      ProposerVet {
        id
        vet_name
        vet_id
        createdAt
        updatedAt
      }
      CartItems {
        items {
          id
          age
          breed_group
          breed
          premium_excl_gst
          cgst
          sgst
          igst
          microchip
          microchip_excl_gst
          plan
          sum_insured
          quantity
          cartID
          createdAt
          updatedAt
        }
        nextToken
      }
      tncagreed
      createdAt
      updatedAt
      cartProposerUserId
      cartProposerVetId
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      status
      total_amount
      ProposerUser {
        id
        fullName
        mobile
        email
        pan
        addressLine1
        addressLine2
        city
        state
        pincode
        gstin
        geoCordinates {
          lat
          log
        }
        countryCode
        addressType
        createdAt
        updatedAt
      }
      ProposerVet {
        id
        vet_name
        vet_id
        createdAt
        updatedAt
      }
      CartItems {
        items {
          id
          age
          breed_group
          breed
          premium_excl_gst
          cgst
          sgst
          igst
          microchip
          microchip_excl_gst
          plan
          sum_insured
          quantity
          cartID
          createdAt
          updatedAt
        }
        nextToken
      }
      tncagreed
      createdAt
      updatedAt
      cartProposerUserId
      cartProposerVetId
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      status
      total_amount
      ProposerUser {
        id
        fullName
        mobile
        email
        pan
        addressLine1
        addressLine2
        city
        state
        pincode
        gstin
        geoCordinates {
          lat
          log
        }
        countryCode
        addressType
        createdAt
        updatedAt
      }
      ProposerVet {
        id
        vet_name
        vet_id
        createdAt
        updatedAt
      }
      CartItems {
        items {
          id
          age
          breed_group
          breed
          premium_excl_gst
          cgst
          sgst
          igst
          microchip
          microchip_excl_gst
          plan
          sum_insured
          quantity
          cartID
          createdAt
          updatedAt
        }
        nextToken
      }
      tncagreed
      createdAt
      updatedAt
      cartProposerUserId
      cartProposerVetId
    }
  }
`;
export const createCartItems = /* GraphQL */ `
  mutation CreateCartItems(
    $input: CreateCartItemsInput!
    $condition: ModelCartItemsConditionInput
  ) {
    createCartItems(input: $input, condition: $condition) {
      id
      age
      breed_group
      breed
      premium_excl_gst
      cgst
      sgst
      igst
      microchip
      microchip_excl_gst
      plan
      sum_insured
      quantity
      cartID
      createdAt
      updatedAt
    }
  }
`;
export const updateCartItems = /* GraphQL */ `
  mutation UpdateCartItems(
    $input: UpdateCartItemsInput!
    $condition: ModelCartItemsConditionInput
  ) {
    updateCartItems(input: $input, condition: $condition) {
      id
      age
      breed_group
      breed
      premium_excl_gst
      cgst
      sgst
      igst
      microchip
      microchip_excl_gst
      plan
      sum_insured
      quantity
      cartID
      createdAt
      updatedAt
    }
  }
`;
export const deleteCartItems = /* GraphQL */ `
  mutation DeleteCartItems(
    $input: DeleteCartItemsInput!
    $condition: ModelCartItemsConditionInput
  ) {
    deleteCartItems(input: $input, condition: $condition) {
      id
      age
      breed_group
      breed
      premium_excl_gst
      cgst
      sgst
      igst
      microchip
      microchip_excl_gst
      plan
      sum_insured
      quantity
      cartID
      createdAt
      updatedAt
    }
  }
`;
export const createVets = /* GraphQL */ `
  mutation CreateVets(
    $input: CreateVetsInput!
    $condition: ModelVetsConditionInput
  ) {
    createVets(input: $input, condition: $condition) {
      id
      name
      name_lc
      clinic_name
      vet_reg_no
      mobile
      email
      address
      createdAt
      updatedAt
    }
  }
`;
export const updateVets = /* GraphQL */ `
  mutation UpdateVets(
    $input: UpdateVetsInput!
    $condition: ModelVetsConditionInput
  ) {
    updateVets(input: $input, condition: $condition) {
      id
      name
      name_lc
      clinic_name
      vet_reg_no
      mobile
      email
      address
      createdAt
      updatedAt
    }
  }
`;
export const deleteVets = /* GraphQL */ `
  mutation DeleteVets(
    $input: DeleteVetsInput!
    $condition: ModelVetsConditionInput
  ) {
    deleteVets(input: $input, condition: $condition) {
      id
      name
      name_lc
      clinic_name
      vet_reg_no
      mobile
      email
      address
      createdAt
      updatedAt
    }
  }
`;
export const createProposerVet = /* GraphQL */ `
  mutation CreateProposerVet(
    $input: CreateProposerVetInput!
    $condition: ModelProposerVetConditionInput
  ) {
    createProposerVet(input: $input, condition: $condition) {
      id
      vet_name
      vet_id
      createdAt
      updatedAt
    }
  }
`;
export const updateProposerVet = /* GraphQL */ `
  mutation UpdateProposerVet(
    $input: UpdateProposerVetInput!
    $condition: ModelProposerVetConditionInput
  ) {
    updateProposerVet(input: $input, condition: $condition) {
      id
      vet_name
      vet_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteProposerVet = /* GraphQL */ `
  mutation DeleteProposerVet(
    $input: DeleteProposerVetInput!
    $condition: ModelProposerVetConditionInput
  ) {
    deleteProposerVet(input: $input, condition: $condition) {
      id
      vet_name
      vet_id
      createdAt
      updatedAt
    }
  }
`;
export const createPremiumChart = /* GraphQL */ `
  mutation CreatePremiumChart(
    $input: CreatePremiumChartInput!
    $condition: ModelPremiumChartConditionInput
  ) {
    createPremiumChart(input: $input, condition: $condition) {
      id
      plan
      breed_group
      sum_insured
      age_bracket
      premium_excl_gst
      premium_incl_gst
      createdAt
      updatedAt
    }
  }
`;
export const updatePremiumChart = /* GraphQL */ `
  mutation UpdatePremiumChart(
    $input: UpdatePremiumChartInput!
    $condition: ModelPremiumChartConditionInput
  ) {
    updatePremiumChart(input: $input, condition: $condition) {
      id
      plan
      breed_group
      sum_insured
      age_bracket
      premium_excl_gst
      premium_incl_gst
      createdAt
      updatedAt
    }
  }
`;
export const deletePremiumChart = /* GraphQL */ `
  mutation DeletePremiumChart(
    $input: DeletePremiumChartInput!
    $condition: ModelPremiumChartConditionInput
  ) {
    deletePremiumChart(input: $input, condition: $condition) {
      id
      plan
      breed_group
      sum_insured
      age_bracket
      premium_excl_gst
      premium_incl_gst
      createdAt
      updatedAt
    }
  }
`;
export const createBreed = /* GraphQL */ `
  mutation CreateBreed(
    $input: CreateBreedInput!
    $condition: ModelBreedConditionInput
  ) {
    createBreed(input: $input, condition: $condition) {
      id
      group
      label
      lc_label
      createdAt
      updatedAt
    }
  }
`;
export const updateBreed = /* GraphQL */ `
  mutation UpdateBreed(
    $input: UpdateBreedInput!
    $condition: ModelBreedConditionInput
  ) {
    updateBreed(input: $input, condition: $condition) {
      id
      group
      label
      lc_label
      createdAt
      updatedAt
    }
  }
`;
export const deleteBreed = /* GraphQL */ `
  mutation DeleteBreed(
    $input: DeleteBreedInput!
    $condition: ModelBreedConditionInput
  ) {
    deleteBreed(input: $input, condition: $condition) {
      id
      group
      label
      lc_label
      createdAt
      updatedAt
    }
  }
`;
export const createProposerUser = /* GraphQL */ `
  mutation CreateProposerUser(
    $input: CreateProposerUserInput!
    $condition: ModelProposerUserConditionInput
  ) {
    createProposerUser(input: $input, condition: $condition) {
      id
      fullName
      mobile
      email
      pan
      addressLine1
      addressLine2
      city
      state
      pincode
      gstin
      geoCordinates {
        lat
        log
      }
      countryCode
      addressType
      createdAt
      updatedAt
    }
  }
`;
export const updateProposerUser = /* GraphQL */ `
  mutation UpdateProposerUser(
    $input: UpdateProposerUserInput!
    $condition: ModelProposerUserConditionInput
  ) {
    updateProposerUser(input: $input, condition: $condition) {
      id
      fullName
      mobile
      email
      pan
      addressLine1
      addressLine2
      city
      state
      pincode
      gstin
      geoCordinates {
        lat
        log
      }
      countryCode
      addressType
      createdAt
      updatedAt
    }
  }
`;
export const deleteProposerUser = /* GraphQL */ `
  mutation DeleteProposerUser(
    $input: DeleteProposerUserInput!
    $condition: ModelProposerUserConditionInput
  ) {
    deleteProposerUser(input: $input, condition: $condition) {
      id
      fullName
      mobile
      email
      pan
      addressLine1
      addressLine2
      city
      state
      pincode
      gstin
      geoCordinates {
        lat
        log
      }
      countryCode
      addressType
      createdAt
      updatedAt
    }
  }
`;
