import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Flex,
    Card,
    Button,
    Divider,
    Image,
    Collection,
    Text,
    View,
    Link,
    Grid,
    CheckboxField,
    StepperField,
} from '@aws-amplify/ui-react';
import {
    CartStateContext,
    CartDispatchContext,
    removeFromCart,
    addressValidation,
    tncAgreedClicked,
    clearCart,
    openDialog,
    addToCart,
} from "../contexts/cart";
import { ageLabel } from '../contexts/quotes';
import Modal from 'react-modal';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


export default function CartUI(props) {
    const { items, address, tncAgreed } = useContext(CartStateContext);
    const dispatch = useContext(CartDispatchContext);
    const navigate = useNavigate();
    const [showTncDialog, setShowTncDialog] = useState(false);
    const [pdfFileName, setPdfFileName] = useState(false);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    useEffect(() => {
        if (showTncDialog) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = "scroll";
        }
    }, [showTncDialog])

    function handleShowTnc() {
        setPdfFileName('pdf/Digit_Pet_Insurance_Policy_Wordings.pdf');
        setShowTncDialog(true);
        openDialog(dispatch, true);
    }

    function closeTncDialog() {
        setShowTncDialog(false);
        openDialog(dispatch, false);
    }

    const handleRemove = (quoteId) => {
        if (!props?.isLoading) removeFromCart(dispatch, quoteId)
    };

    const handleBackToQuote = () => {
        if (!props?.isLoading) navigate("/quote");
    };

    const handleTncAgreeClicked = (checked) => {
        if (!props?.isLoading) {
            tncAgreedClicked(dispatch, checked);
            addressValidation(dispatch, address);
        }
    }
   


    function handleClearCart() {
        if (!props?.isLoading) clearCart(dispatch);
    }

    const microchip_unit_price_excl_gst = 423.73;
    const microchip_unit_price_incl_gst = 500;


    const microchip = { id: 'microchip999', "microchip": true, age: 'X', breed_group: 'MIXED', breed: '', sum_insured: 500, plan: 'MICROCHIP', quantity: 1, premium_incl_gst: microchip_unit_price_incl_gst, premium_excl_gst: microchip_unit_price_excl_gst };

    const handleAddRemoveMicrochip = (newValue) => {
        //let currQty =!isNaN(items?.reduce((prev,curr) => { if (curr?.id === 'microchip999') return curr?.quantity;}))?(items?.reduce((prev,curr) => { if (curr?.id === 'microchip999') return curr?.quantity;})):0;
        removeFromCart(dispatch, 'microchip999')
        if (newValue > 0) addToCart(dispatch, { ...microchip, quantity: newValue });
    };

    const display = items?.length > 0 ? 'block' : 'none';

    const dialogStyle = {
        overlay: {zIndex: 1000},
        content: {
          border: '0',
          borderRadius: '4px',
          bottom: 'auto',
          //height: '600px',  // set height
          left: '1%',
          padding: '2rem',
          //position: 'fixed',
          right: 'auto',
          top: '1%', // start from center
          //transform: 'translate(-50%,-' + offsetPx + ')', // adjust top "up" based on height
          width: '80%',
          //maxWidth: '40rem'
          //overflow: 'hidden'
        }
      };

    return (
        <Card variation="outlined" >
            <div className="offer-tab">
                <div className="marquee">
                    <span> Pet identification chip is mandatory, contact +91-20-67445858 customerservice@pawtectindia.com</span>
                </div>
            </div>

            <Flex direction={'column'} alignContent={'center'} alignItems={'center'} >
                <Collection
                    type="grid"
                    items={items}
                    isPaginated={items?.length > 3}
                    itemsPerPage={3}
                    searchNoResultsFound={
                        <Card padding={'0px'}>
                            <Flex paddingBottom={'10px'} direction={'row'} alignItems='start'>
                                <Text variation={"tertiary"} fontSize={'0.9rem'} style={{ 'textAlign': 'left' }} as='p'>
                                    There is nothing to checkout, Looks like you are still not decided on healtcare plans for your family members. Click "Add Pet" to choose </Text>
                                <View>
                                    <Button variation="primary" fontSize={'0.9rem'} height={'50px'} isDisabled={props?.isLoading}
                                        onClick={handleBackToQuote}
                                        width={'150px'}
                                    >
                                        Add Pet
                                    </Button>
                                </View>
                            </Flex>
                            <Image src='img/sad_dog.jpg' width={'100%'} alt={'sad dog'}></Image>
                        </Card>
                    }
                >
                    {(quote, index) => (
                        <Card key={quote?.id + index} paddingTop={'5px'} paddingBottom={'5px'} variation="elevated" width={'100%'}>
                            <Grid
                                templateColumns={{ base: '1.25fr 1fr 1fr 1fr', large: '1.25fr 2.5fr 1.5fr 0.5fr 1fr 1fr 1fr 0.5fr' }}
                                columnGap={{ base: '5px', large: '20px' }}
                            >
                                {quote?.plan === 'RED' && (
                                    <div className="color-red" style={{ 'textAlign': 'right', 'fontSize': '0.9rem' }}>
                                        <i className="icon-ribbon" style={{ 'fontSize': '24px' }} />
                                        <br />
                                        <span > RED </span>
                                    </div>
                                )
                                }
                                {quote?.plan === 'YELLOW' && (
                                    <div className="color-yellow" style={{ 'textAlign': 'right', 'fontSize': '0.9rem' }} >
                                        <i className="icon-ribbon" style={{ 'fontSize': '24px' }} />
                                        <br />
                                        <span > YELLOW </span>
                                    </div>
                                )
                                }
                                {quote?.plan === 'BLUE' && (
                                    <div className="color-blue" style={{ 'textAlign': 'right', 'fontSize': '0.9rem' }}>
                                        <i className="icon-ribbon" style={{ 'fontSize': '24px' }} />
                                        <br />
                                        <span > BLUE </span>
                                    </div>
                                )
                                }
                                {quote?.plan === 'MICROCHIP' && (
                                    <div className="color-green" style={{ 'textAlign': 'right', 'fontSize': '0.9rem' }}>
                                        <Image
                                            alt={'microchip'}
                                            src={'img/microchip.svg'}
                                            width={'30px'}
                                            height={'30px'}
                                        >
                                        </Image>
                                        <br />
                                        <span > Microchip </span>
                                    </div>
                                )
                                }
                                <Text variation={"tertiary"} fontSize={'0.9rem'} style={{ 'textAlign': 'right' }} as='p'>{ageLabel?.get(quote.age)}<br />{quote?.breed}</Text>
                                <Text style={{ 'textAlign': 'right' }} fontSize={'0.9rem'} variation="tertiary" as='p'>{quote?.plan !== 'MICROCHIP' ? 'Sum Insured' : 'Unit Price'}<br />{'₹' + (quote?.sum_insured)?.toLocaleString('en-IN', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}</Text>
                                <Text style={{ 'textAlign': 'right' }} fontSize={'0.9rem'} variation="tertiary" as='p'>{'Pets'}<br />{quote?.quantity}</Text>
                                <Text style={{ 'textAlign': 'right' }} fontSize={'0.9rem'} variation="tertiary" as='p'>{'Tax'}<br />{'₹' + (0.18 * quote?.quantity * quote?.premium_incl_gst / 1.18)?.toLocaleString('en-IN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}</Text>
                                <Text style={{ 'textAlign': 'right' }} fontSize={'0.9rem'} variation="tertiary" as='p'>{quote?.plan !== 'MICROCHIP' ? 'Premium' : 'Sub Total'}<br />{'₹' + (quote?.quantity * quote?.premium_incl_gst / 1.18)?.toLocaleString('en-IN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}</Text>
                                <Text style={{ 'textAlign': 'right', 'color': 'green' }} fontSize={'0.9rem'} variation="secondary" as='p'>{'Sub Total'}<br /><strong>{'₹' + (quote?.quantity * quote?.premium_incl_gst)?.toLocaleString('en-IN', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}</strong></Text>
                                <Link style={{ 'textAlign': 'right' }} href="#" onClick={() => handleRemove(quote?.id)} isDisabled={props?.isLoading}><i className="icon-delete color-red" /></Link>
                            </Grid> {
                                index < items.length - 1 ?
                                    <Divider></Divider> : ''
                            }
                        </Card>
                    )}
                </Collection>
                <Card display={display}>
                    <StepperField
                        onStepChange={handleAddRemoveMicrochip}
                        value={!isNaN(items?.length > 0 && items?.reduce((prev, curr) => { return (curr?.id === 'microchip999' ? curr?.quantity : 0) })) ? (items?.length > 0 && items?.reduce((prev, curr) => { return (curr?.id === 'microchip999' ? curr?.quantity : 0) })) : 0}
                        max={100}
                        min={0}
                        step={1}
                        label="Buy Microchip (₹ 500 each) - We will express ship Pet identification chip at your address and you can implant by your nearest vet."
                    />
                    <br />
                    <Text variation={'tertiary'} fontSize={'0.7rem'} as='p'> The options suggested herein are just out of permutation of various coverages under Digit Pet Insurance Policy UIN: IRDAN158RP0006V01202223 for customer convenience. For more details, please read and refer to policy terms and conditions carefully before enrollement. Vetina Healtcare LLP is acting as a Group Manager to enable customers to enroll for insurance cover arranged through Go Digit General Insurance Limited.Note: Apart from the option chosen, your pet insurance premium will be calculated based on your dog’s age and breed. Customer will need to submit application form for every pet for enrollement, The policy will be issued after scrutiny and processing of application form(s) by our partner Go Digit General Insurance Ltd. In case customer does not provide application form within 7 days or application is not accepted by Go Digit General Insurance Ltd, Full refund for premium payments made by you and received by us will be done.</Text>
                    <br />
                    <Divider></Divider>
                    <Grid
                        templateColumns={{ base: '0.25fr 7.75fr', large: '0.25fr 7.75fr' }}
                        columnGap={{ base: '10px', large: '10px' }}
                        rowGap={{ base: '10px', large: '10px' }}
                    >
                        <CheckboxField
                            checked={tncAgreed ? tncAgreed : false}
                            size={'large'}
                            value={tncAgreed ? true : false}
                            onChange={(e) => handleTncAgreeClicked(e?.target?.checked)}
                            isDisabled={props?.isLoading}
                        ></CheckboxField>
                        <View>
                            <Text variation={'tertiary'} fontSize={'.90rem'} as='span'>I hereby agree to avail insurance cover under </Text>
                            <Link variation={'tertiary'} fontSize={'1.0rem'} onClick={handleShowTnc} href='#'>Digit Pet Insurance Policy – UIN: IRDAN158RP0006V01202223</Link>
                            <Text variation={'tertiary'} fontSize={'.90rem'} as='span'> arranged by Vetina Healthcare LLP from Go Digit General Insurance Limited. I hereby declare that I have read policy Terms and Conditions carefully </Text>
                            <br />

                        </View>
                    </Grid>
                    <Grid
                        templateColumns={{ base: '1fr 1fr 1fr 1fr', large: '1fr 1fr 1fr 1fr' }}
                        columnGap={{ base: '15px', large: '15px' }}
                        rowGap={{ base: '20px', large: '20px' }}
                        padding={'10px'}
                        paddingTop={'20px'}
                    >
                        <Button variation="primary" fontSize={'0.9rem'} height={'50px'} isDisabled={props?.isLoading}
                            onClick={handleBackToQuote}
                            columnStart={2}
                        >
                            Add Pet
                        </Button>
                        <Button variation="primary" fontSize={'0.9rem'} height={'50px'} isDisabled={props?.isLoading}
                            onClick={handleClearCart}
                            columnStart={3}
                        >
                            Empty Cart
                        </Button>
                        <Button variation="primary" fontSize={'0.9rem'}
                            height={'50px'}
                            isDisabled={(items?.length === 0) || !(tncAgreed ? tncAgreed : false)/*|| !(address?.otpVerificationStatus)  || !(address?.addressValidationStatus) */ || props?.isLoading}
                            onClick={props?.handleProceedCheckout}
                            columnStart={4}
                            isLoading={props?.isLoading}
                            loadingText={'Payment Gateway'}
                        >
                            {'Pay ₹' + (items?.length > 0 && items?.reduce((prev, curr) => { return prev = prev + curr?.quantity * curr?.premium_incl_gst }, 0))?.toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
                        </Button>
                    </Grid>
                </Card>
            </Flex>
            <Modal
                isOpen={showTncDialog}
                //preventScroll={true}
                style={dialogStyle}
            >
                <Grid rowGap={'20px'} alignItems={'end'}>
                    <Button
                        variation="primary"
                        width={'150px'}
                        style={{ 'justifySelf': 'end' ,background:'#20bf6b'}}
                        onClick={closeTncDialog}
                    >
                        Close
                    </Button>
                    <div style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        height: '500px',
                    }}>
                        <Viewer
                            fileUrl={pdfFileName}
                            plugins={[defaultLayoutPluginInstance]}
                        />
                    </div>
                </Grid>
            </Modal>
        </Card >
    );
};
