import React from "react";
import {
    Card,
} from '@aws-amplify/ui-react';
//import PaymentAddressDetails from "./PaymentAddressDetails";
//import PaymentCartDetails from "./PaymentCartDetails";
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import Modal from 'react-modal';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
Amplify.configure(awsconfig);




export default function Help(props) {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    Modal.setAppElement('#root');

    return (
        <Card>
            <div className="page-process-wrapper border-top">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col">
                            <div>
                                <h2> Thank you for showing interest for enrolling under the Digit Pet Insurance policy, The Digit Pet Insurance is now available on <a href='https://www.pawtectindia.com' >www.pawtectindia.com</a> . In case you have bought policy with us in past , We would like to bring in your notice that current policy has new coverage(s); Hence all the details of your pet needs to be filled again. Following are steps for buying a policy. You can also contact +91-7506562266 /+91-20-67445858 for any assitance between 9AM-5PM Monday to Friday</h2>

                                <p>
                                    Step 1 - Click the Quote button on www.pawtectindia.com and enter age and breed of your pet , tick the checkbox for "By clicking 'QUOTE', you agree to join Vetina family and receive our newsletters, communications & promotional offers".
                                </p>
                                <p>
                                    Step 2 - Select appropriate plan ( i.e. Blue / Red / Yellow ) along with  desired sum insured ( ₹40000, ₹60000, ₹100000, ₹150000 ) and click the Cart icon on top right corner and click Checkout & Pay button.
                                </p>
                                <p>
                                    Step 3 - On left pane fill in all the details and including Consulting Veterinarian , Your Name , Mobile , Email and Address . You can search for nearest area / locality in "Search Address" and select the address, thereafter provides more information like flat / bunglow number , building name , street name in respective text box.
                                </p>
                                <p>
                                    Step 4 - Tick the Checkbox for "I hereby agree to avail insurance cover under Digit Pet Insurance Policy – UIN: IRDAN158RP0006V01202223 arranged by Vetina Healthcare LLP from Go Digit General Insurance Limited. I hereby declare that I have read policy Terms and Conditions carefully"
                                </p>
                                <p>
                                    Step 5 - Click Pay button and you will be redirected to Payement Gateway page with various payment options , You can also enter any discount coupons shared by your Consulting Veterinarian on this page . Complete the premium payment
                                </p>
                                <p>
                                    Step 6 - After successful payment , you will be redirected to www.pawtectindia.com and you will receive application form over email. Fill in all the details in application form and attach the vaccination card and photo(s) of your pet and send application form to customerservice@pawtectindia.com  
                                </p>
                                <p>
                                    Step 7 - Digit and Vetina will scrutnize your application form and may contact you for additional details . After scrutny policy will be issued or rejected in case of rejection full refund of amount paid by you will be issued to original mode of payment.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <Modal
                isOpen={false}
                style={customStyles}
                contentLabel="Pawtect Payment"
                ariaHideApp={false}
            >
            </Modal>
        </Card>
    );
}


