import React from "react";
import {
  Card,
} from '@aws-amplify/ui-react';
//import PaymentAddressDetails from "./PaymentAddressDetails";
//import PaymentCartDetails from "./PaymentCartDetails";
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import Modal from 'react-modal';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import * as cccrypto from "crypto-browserify";
Amplify.configure(awsconfig);


const decrypt = function (encText) {
  var decoded = ''
  //console.log('encText-->' + encText);
  if (encText) {
    try {
      var m = cccrypto.createHash('md5');
      m.update('930F9A992F46338D7B32A2C6CD13F06C');
      var key = m.digest();
      var iv = '\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f';
      var decipher = cccrypto.createDecipheriv('aes-128-cbc', key, iv);
      decoded = decipher.update(encText, 'hex', 'utf8');
      decoded += decipher.final('utf8');
    } catch (error) {
      //console.log('Error' + error);
    }
  }
  //console.log('decoded-->' + decoded);
  return decoded;
};

export default function Payment(props) {
  //console.log('propos-->' + JSON.stringify(props));
  const resp = JSON.parse(decrypt(props?.encp));

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  Modal.setAppElement('#root');

  return (
    <Card>
      <div className="page-process-wrapper border-top">
        <div className="container">
          <div className="row no-gutters">
            <div className="col">
              {
                resp?.order_status === 'Success' ? (
                  <div>
                    <h2>Dear {resp?.billing_name} ,Thank you for showing interest in enrolling under the Digit Pet Insurance policy. Please be noted that, risk shall not commence upon submission of interest but upon your submission of application form, basis which risk shall be underwritten by Go Digit General Insurance and Certificate of Insurance for approved proposals shall be issued post 64VB compliance on your registered email id. Your payment for ₹{resp?.amount}  was successful. You will be receiving payment receipt on email and application form(s) for each pet , fill up , print , scan and email to customerservice@pawtectindia.com within 3 days. The application form(s) will be scrutnized and processed by Go Digit for policy issuance. Your order id for records is {resp?.order_id}</h2>
                    <div className="clearfix"></div>
                    <div className="page-process-bg">
                      <div className="progress-line"></div>
                      <div className="progress-complete-line" style={{ width: '100%' }}></div>
                      <div className="progress-content user-details progress-complete">
                        <a href="#">
                          <div className="content-icon"><i className="icon-brochure"></i></div>
                          Receive email with application form(s)
                        </a>
                      </div>
                      <div className="progress-content pet-details progress-complete">
                        <a href="pet-details.html">
                          <div className="content-icon"><i className="icon-summary"></i></div>
                          Fill up pet details and health declarations
                        </a>
                      </div>
                      <div className="progress-content summary progress-complete">
                        <a href="summary.html">
                          <div className="content-icon"><i className="icon-user-details"></i></div>
                          Print and Sign application form(s)
                        </a>
                      </div>
                      <div className="progress-content payment-gateway progress-complete">
                        <a href="payment-gateway.html">
                          <div className="content-icon"><i className="icon-payment-gateway"></i></div>
                          Scan & Email signed application form(s)
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h2>Dear {resp?.billing_name} ,Thank you for showing interest in enrolling under the Digit Pet Insurance policy. Please be noted that, risk shall not commence upon submission of interest but upon your submission of application form, basis which risk shall be underwritten by Go Digit General Insurance and Certificate of Insurance for approved proposals shall be issued post 64VB compliance on your registered email id. Your payment for ₹{resp?.amount}  has failed ({resp?.order_status}). Your order id for failed payment is  {resp?.order_id} and tracking number is {resp?.tracking_id}</h2>
                    <div className="clearfix"></div>
                    <div className="page-process-bg">
                      <div className="progress-line"></div>
                      <div className="progress-complete-line" style={{ width: '100%' }}></div>
                      <div className="progress-content user-details progress-complete">
                        <a href="#">
                          <div className="content-icon"><i className="icon-brochure"></i></div>
                          Contact customer service in case money has been debited from your account and wait before retry
                        </a>
                      </div>
                      <div className="progress-content pet-details progress-complete">
                        <a href="pet-details.html">
                          <div className="content-icon"><i className="icon-summary"></i></div>
                          Retry Payment by going back to your cart
                        </a>
                      </div>
                      <div className="progress-content summary progress-complete">
                        <a href="summary.html">
                          <div className="content-icon"><i className="icon-user-details"></i></div>
                          New order id and tracking number will be generated
                        </a>
                      </div>
                      <div className="progress-content payment-gateway progress-complete">
                        <a href="payment-gateway.html">
                          <div className="content-icon"><i className="icon-payment-gateway"></i></div>
                          After successful payment application form(s) will be emailed
                        </a>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
      <Modal
        isOpen={false}
        style={customStyles}
        contentLabel="Pawtect Payment"
        ariaHideApp={false}
      >
      </Modal>
    </Card>
  );
}


