import React, { useContext, useEffect } from "react";
import {
    Grid,
    Card,
    View,
    Text,
    Button,
    TextField,

    TextAreaField,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {
    LocationSearch,
} from '@aws-amplify/ui-react-geo';
import '@aws-amplify/ui-react-geo/styles.css';

import {
    CartStateContext,
    CartDispatchContext,
    addressValidation,
    clearAddress,
    vetSelected,
    emailRegex,
    mobileRegex
} from "../contexts/cart";
import VetSearch from "./VetSearch";

export default function AddressInput(props) {
    const { address, vet, dialogOpen } = useContext(CartStateContext);
    const dispatch = useContext(CartDispatchContext);
    //const [fullAddress, setFullAddress] = useState("");

    function selectVet(selected) {
        //console.log('selectVet called :' + selected[0]?.id)
        //console.log('selectVet called :' + selected[0]?.name)
        if (selected) {
            if (selected[0]) {
                //To DO update context
                vetSelected(dispatch, { id: selected[0]?.id, name: selected[0]?.name })
            }
        }

    }

    // Return array of string values, or NULL if CSV string not well formed.
    function cSVtoArray(text) {
        var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
        var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
        // Return NULL if input string is not well formed CSV string.
        if (!re_valid.test(text)) return null;
        var a = [];                     // Initialize array to receive values.
        text.replace(re_value, // "Walk" the string using replace with callback.
            function (m0, m1, m2, m3) {
                // Remove backslash from \' in single quoted values.
                if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
                // Remove backslash from \" in double quoted values.
                else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
                else if (m3 !== undefined) a.push(m3);
                return ''; // Return empty string.
            });
        // Handle special case of empty last value.
        if (/,\s*$/.test(text)) a.push('');
        return a;
    };

    function handleSearch(item) {
        //console.log('handleSearch called')
        //console.log(item)
        let address = (item) ? cSVtoArray(item?.text)[0] : '';
        let area = item?.properties?.neighborhood;
        let city = item?.properties?.subRegion;
        let municipality = item?.properties?.municipality;
        let city1 = (city !== municipality) ? (municipality + " " + city) : city;
        city1 = (city1 !== null && city1 !== undefined ) ? ( city1.includes("Twenty Four Parganas") ? city1.replace("Twenty Four Parganas",""):city1) :city1
        city1 = (city1 !== null && city1 !== undefined ) ? city1.substring(0, Math.min(29,city1.length)) : city1
        let state = item?.properties?.region;
        let pincode = item?.properties?.postalCode;

        //setFullAddress((address ? address + '\n' : '') + (area ? area : '') + '\n' + (city1 && state && pincode ? city1 + ' - ' + state + ' - ' + pincode : ''));
        addressValidation(dispatch, { ...address, addressLine: address, area: area, city: city1, state: state, pincode: pincode, });
        //console.log(JSON.stringify(address));
        return item.text;
    };

    function handleNameChange(e) {
        addressValidation(dispatch, { ...address, name: e?.target?.value });
    };

    function handleEmailChange(e) {
        let valid = emailRegex.test(e?.target?.value);
        addressValidation(dispatch, { ...address, email: e?.target?.value, isEmailValidated: valid });
    };

    function handleMobileChange(e) {
        let valid = mobileRegex.test(e?.target?.value);
        addressValidation(dispatch, { ...address, mobile: e?.target?.value, isMobileValidated: valid });
    };

    function handleAddressLineChange(e) {
        addressValidation(dispatch, { ...address, addressLine: e?.target?.value });
    };

    function handleClearAddress() {
        if (!props.isLoading) clearAddress(dispatch);
    }

    useEffect(() => {
        //handleVerifyOTP();
    });

    let display = props?.isLoading || dialogOpen ? 'none' : 'block';

    return (
        <Card as='form' variation="outlined">
            <Grid
                columnGap="0.5rem"
                rowGap="0.5rem"
                templateColumns={{ base: '1fr 1fr 1fr', large: '1fr 1fr 1fr' }}
                templateRows={{ base: '15fr', large: '1fr' }}
            >
                <Text variation={'tertiary'} columnSpan={{ base: 1, large: 1 }}> Consulting <br />Veterinarian </Text>
                <VetSearch isDisabled={props?.isLoading} onChange={(selected) => selectVet(selected)} defaultInputValue={vet?.name}></VetSearch>
                <TextField isDisabled={props?.isLoading} autoComplete="off" isRequired={true} key={'name'} placeholder="Pet Owner's Name" errorMessage={'Please enter atleast 3 characters for Name'} hasError={address?.name?.length >= 3 ? false : true} value={address?.name} onChange={handleNameChange} columnSpan={{ base: 3, large: 3 }}></TextField>
                <TextField isDisabled={props?.isLoading} autoComplete="off" isRequired={true} key={'email'} placeholder="Pet Owner's Email" errorMessage={'Please enter valid email address'} hasError={!(address?.isEmailValidated)} onChange={handleEmailChange} value={address?.email} columnSpan={{ base: 3, large: 3 }}></TextField>
                <TextField isDisabled={props?.isLoading} autoComplete="off" isRequired={true} key={'mobile'} errorMessage={'Please enter valid 10 digit mobile'} hasError={!(address?.isMobileValidated)} placeholder="Pet Owner's Mobile" value={address?.mobile} onChange={handleMobileChange} columnSpan={{ base: 3, large: 3 }}></TextField>
                <Text columnSpan={{ base: 1, large: 1 }} variation={'tertiary'} display={display}> Search <br />Address </Text>
                <View columnSpan={{ base: 2, large: 2 }} paddingTop={'10px'} display={display}>
                    <LocationSearch
                        placeholder="Search & select address"
                        minLength="3"
                        countries="IND"
                        trackProximity="true"
                        getItemValue={handleSearch}
                        autoComplete="off"
                    />
                </View>
                <TextField isDisabled={props?.isLoading} autoComplete="off" isRequired={true} placeholder={"Pet Owner's Address"} onChange={handleAddressLineChange} value={address?.addressLine} columnSpan={3}></TextField>
                <TextAreaField /** hasError={!(address?.addressValidationStatus)} errorMessage={"Search address closer to have atleast Area , City & Pincode"} */ isRequired={true} placeholder={'Area , City , State'} value={address ? ((address?.addressLine ? address?.addressLine + '\n' : '') + (address?.area ? address?.area + '\n' : '') + (address?.pincode ? address?.city + ' - ' + address?.state + ' - ' + address?.pincode : '')) : ''} columnSpan={{ base: 3, large: 3 }} disabled={true}></TextAreaField>
                <Button variation="primary" fontSize={'0.9rem'} height={'50px'} isDisabled={props?.isLoading}
                    onClick={handleClearAddress}
                    columnStart={1}
                    columnEnd={4}

                >
                    Clear Address
                </Button>
            </Grid>
        </Card>
    );

}

