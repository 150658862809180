/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://yyi47ghcyzablixsxc6vahrvey.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-usp6ukdnpbbvnb5ybtucxoi3ti",
    "aws_cloud_logic_custom": [
        {
            "name": "pawtectrest",
            "endpoint": "https://8y9uwbp88d.execute-api.ap-southeast-1.amazonaws.com/staging",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:275f8991-f941-4720-950d-5c5e260817df",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_SaPuKlHOB",
    "aws_user_pools_web_client_id": "3kmbvdpjf0dlhiiprebftd39rg",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "pawtect-prod-hosting-bucket-staging",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://dkogy232lagyt.cloudfront.net",
    "geo": {
        "amazon_location_service": {
            "region": "ap-southeast-1",
            "search_indices": {
                "items": [
                    "pawtectplaceindex-staging"
                ],
                "default": "pawtectplaceindex-staging"
            }
        }
    }
};


export default awsmobile;
