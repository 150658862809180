import { useState } from "react";
import { API ,graphqlOperation} from 'aws-amplify';
import { listVets } from '../graphql/queries';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';

import {
    View,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
const AsyncTypeahead = withAsync(Typeahead);


export default function VetSearch( props) {

    const [items, setItems] = useState([]);
    const [lastquery, setLastQuery] = useState('');



    async function searchVets(query) {
        let vets = [];
        if (query && query !== '') {
            //console.log(query);
            let filter = { name_lc: { contains: query?.toLowerCase() } }
            let response = await API.graphql(graphqlOperation(listVets, {filter: filter , limit:3000}));
            vets = response?.data?.listVets?.items;
            setLastQuery(query);
            //console.log(vets);
            setItems(vets);
        } else {
            let response = await API.graphql(graphqlOperation(listVets));
            vets = response?.data?.listVets?.items;
            setItems(vets);
        }
        return vets;
    }

   
    return (
        <View columnSpan={{ base: 2, large: 2 }}>
            <AsyncTypeahead style={{ margin: 0 }}
                id="get-quote-typeahead"
                onChange={props?.onChange}
                isLoading={true}
                onSearch={(query) => searchVets(query)}
                options={items}
                useCache={true}
                onFocus={(event) => searchVets(event?.target?.value)}
                promptText={'Type in 3 characters of Vet'}
                emptyLabel={'No matching Vet'}
                defaultInputValue={props?.defaultInputValue ? props?.defaultInputValue: lastquery}
                disabled={props?.isDisabled}
                ignoreDiacritics={true}
                flip={true}
                labelKey={'name'}
                //size={'lg'}
                //autoFocus={true}
                autoComplete="off"
                
            />
        </View>
    );

}

