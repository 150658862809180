import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  Flex,
  Grid,
  Card,
  Button,
  Divider,
  Image,
  Text
} from '@aws-amplify/ui-react';

import {
  Link
} from 'react-router-dom';

import {
  CartStateContext,
  CartDispatchContext,
  removeFromCart,
  toggleCartPopup,
} from "../contexts/cart";

import { ageLabel } from '../contexts/quotes';

const CartPreview = () => {
  const { items, isCartOpen, checkout } = useContext(CartStateContext);
  const dispatch = useContext(CartDispatchContext);
  const navigate = useNavigate();

  const handleRemove = (quoteId) => {
    return removeFromCart(dispatch, quoteId);
  };

  const handleProceedCheckout = (event) => {
    event.preventDefault();
    toggleCartPopup(dispatch);
    navigate("/checkout");
  };


  let cartTotal = 0;

  return (
    <div className={classNames("cart-preview", { active: isCartOpen })}>
      <Flex direction={'column'} alignContent={'center'} alignItems={'center'} >
        <ul className="cart-items">
          {
            items.map((quote) => {
              if (quote) {
                cartTotal = cartTotal + quote?.quantity * quote?.premium_incl_gst;

                return (
                  <Card key={quote?.id} paddingTop={'10px'} paddingBottom={'5px'}>
                    <Grid
                      templateColumns={{ base: '1fr 3fr 0.25fr 0.75fr 0.5fr', large: '1fr 3fr 0.25fr 1fr 0.25fr' }}
                      columnGap={{ base: '5px', large: '10px' }}
                      alignItems={'start'}
                      alignContent={'start'}
                    >
                      {quote?.plan === 'RED' && (
                        <div className="color-red">
                          <span style={{ 'fontSize': '12px' }}>RED</span><br />
                          <i className="icon-ribbon" style={{ 'fontSize': '24px' }} />
                        </div>
                      )
                      }
                      {quote?.plan === 'YELLOW' && (
                        <div className="color-yellow">
                          <span style={{ 'fontSize': '12px' }}>YELLOW</span><br />
                          <i className="icon-ribbon" style={{ 'fontSize': '24px' }} />
                        </div>
                      )
                      }
                      {quote?.plan === 'BLUE' && (
                        <div className="color-blue">
                          <span style={{ 'fontSize': '12px' }}>BLUE</span><br />
                          <i className="icon-ribbon" style={{ 'fontSize': '24px' }} />
                        </div>
                      )
                      }
                      {quote?.plan === 'MICROCHIP' && (
                        <div className="color-green" style={{ 'textAlign': 'left' }}>
                          <span style={{ 'fontSize': '12px' }}> Microchip </span><br/>
                          <Image
                            alt={'microchip'}
                            src={'img/microchip.svg'}
                            width={'25px'}
                            height={'25px'}
                          >
                          </Image>
                        </div>
                      )
                      }
                      <div className="cart-item product-info">
                        <p className="product-name">{ageLabel.get(quote?.age) + quote?.breed + (quote?.plan !== 'MICROCHIP'?' old  SI:₹':' Unit Price: ₹') + (quote?.sum_insured)?.toLocaleString('en-IN',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</p>
                        <p></p>
                      </div>
                      <div className="cart-item product-total">
                        <p className="quantity">
                          {`${quote.quantity} ${quote?.quantity > 1 ? "Pets" : "Pet"
                            }`}
                        </p>
                      </div>
                      <div className="cart-item product-total">
                        <p className="amount">{'₹' + (quote?.quantity * quote?.premium_incl_gst)?.toLocaleString('en-IN',{minimumFractionDigits: 0, maximumFractionDigits: 2})}</p>
                      </div>
                      <div className="cart-item product-total">
                        <Button
                          className="cart-item product-remove"
                          onClick={() => handleRemove(quote?.id)}
                          isDisabled={checkout}
                        >
                          ×
                        </Button>
                      </div>
                    </Grid>
                    <Divider></Divider>
                  </Card>
                )
              };
            })
          }
          {
            items && items.length === 0 ?
              <Card>
                <Image src='img/sad_dog.jpg'></Image>
                Your cart is empty , Please select plans for enrollement
              </Card> : ''
          }
        </ul>
        <Button variation="primary" isDisabled={items?.length === 0 || checkout}
          onClick={handleProceedCheckout}
        >
          Checkout & Pay {' ₹' + (cartTotal)?.toLocaleString('en-IN',{minimumFractionDigits: 0, maximumFractionDigits: 2})}
        </Button>
        <Card paddingTop={'0px'} paddingBottom={'5px'}>
          <Text variation={'tertiary'} fontSize={'0.6rem'} as='p'>The options suggested herein are just out of permutation of various coverages under Digit Pet Insurance Policy UIN: IRDAN158RP0006V01202223 for customer convenience. For more details, please read and refer to policy terms and conditions carefully before enrollement.Note: Apart from the option chosen, your pet insurance premium will be calculated based on your dog’s age and breed.</Text>
        </Card>

      </Flex>

    </div>

  );
};

export default CartPreview;
