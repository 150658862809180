import React, { useReducer, createContext, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const initialState = {
  address: { addressLine: '', area: '', city: '', state: '', pincode: '' ,addressValidationStatus: false},
  isCartOpen: false,
  dialogOpen: false,
  items: [],
  vet: { id: '', name: '' },
  tncAgreed: false,
  vetinaFamily : false,
  checkout: false,
};

export const CartStateContext = createContext();
export const CartDispatchContext = createContext();
export const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9-]+\.[A-Za-z0-9.-]+$/, "gm");
export const mobileRegex = new RegExp(/^[6-9]\d{9}$/, "gi");

const reducer = (state, action) => {

  switch (action?.type) {
    case "TOGGLE_CART_POPUP":
      return {
        ...state,
        isCartOpen: !state.isCartOpen
      };
    case "ADD_TO_CART":
      const id = action.payload.cartItem.id;
      const isOld = state.items.map((item) => item?.id)?.includes(id);
      let cartItems = null;
      if (isOld) {
        const items = state.items.map((item) => {
          if (item?.id === id) {
            return {
              ...item,
              quantity: item?.quantity + 1,
            };
          }
          return item;
        });
        cartItems = [...items];
      } else {
        cartItems = [...state?.items, action?.payload?.cartItem];
      }

      return {
        ...state,
        items: cartItems,
        tncAgreed: false,
      };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        items: state.items.filter(
          (item) => item?.id !== action?.payload?.cartItemId),
        tncAgreed: false,
      };
    case "CLEAR_CART":
      return {
        ...state,
        items: [],
        tncAgreed: false
      };
    case "CLEAR_ADDRESS":
      return {
        ...state,
        address: {},
        tncAgreed: false
      };
    case "AGREE_TNC_CLICKED":
      return {
        ...state,
        tncAgreed: action?.payload?.checked,
      }
    case "VETINA_FAMILY_CLICKED":
        return {
          ...state,
          vetinaFamily: action?.payload?.checked,
        }
    case "CHECKOUT":
      return {
        ...state,
        checkout: action?.payload?.checkout,
      }
    case "VET_SELECTED":
      return {
        ...state,
        vet: action?.payload?.vet,
      }
    case "DIALOG":
      return {
        ...state,
        dialogOpen: action?.payload?.dialogOpen,
      }

    case "ADDRESS_VALIDATION":
      let updatedAddress = state?.address ? { ...state?.address, ...action?.payload?.address } : action?.payload?.address;
      //console.log('ADDRESS_VALIDATION :' + updatedAddress)
      let validatedAddress = handleAddressValidation(updatedAddress);
      return {
        ...state,
        address: validatedAddress
      }

    default:
      throw new Error(`Unknown action: ${action?.type}`);
  }
};

export const toggleCartPopup = (dispatch) => {
  return dispatch({
    type: "TOGGLE_CART_POPUP"
  });
};

export const openDialog = (dispatch,dialogOpen) => {
  return dispatch({
    type: "DIALOG",
    payload: {
      dialogOpen: dialogOpen
    }
  });
};

export const tncAgreedClicked = (dispatch, checked) => {
  return dispatch({
    type: "AGREE_TNC_CLICKED",
    payload: {
      checked: checked
    }
  });
};

export const vetinaFamilyClicked = (dispatch, checked) => {
  return dispatch({
    type: "VETINA_FAMILY_CLICKED",
    payload: {
      checked: checked
    }
  });
};

export const checkoutInProgress = (dispatch, checkout) => {
  return dispatch({
    type: "CHECKOUT",
    payload: {
      checkout: checkout
    }
  });
};


export const addressValidation = (dispatch, address) => {
  return dispatch({
    type: "ADDRESS_VALIDATION",
    payload: {
      address: address
    }
  });
};

export const handleAddressValidation = (updatedAddress) => {
  var addressValidationStatus1 = false;
  if (updatedAddress && updatedAddress?.name && updatedAddress?.name?.length > 3) {
    addressValidationStatus1 = true
  } else {
    addressValidationStatus1 = false
  }
  if (addressValidationStatus1 && updatedAddress && updatedAddress?.email && emailRegex.test(updatedAddress?.email)/* && updatedAddress?.otpVerificationStatus*/) {
    addressValidationStatus1 = true
  } else {
    addressValidationStatus1 = false
  }
  if (addressValidationStatus1 &&  updatedAddress && updatedAddress?.mobile && mobileRegex.test(updatedAddress?.mobile)/* && updatedAddress?.otpVerificationStatus*/) {
    addressValidationStatus1 = true
  } else {
    addressValidationStatus1 = false
  }
  if (addressValidationStatus1 &&  updatedAddress && updatedAddress?.addressLine ) {
    addressValidationStatus1 = true
  } 
  /** 
  else {
    addressValidationStatus1 = false
  }
  */
  if (addressValidationStatus1 &&  updatedAddress && updatedAddress?.city  && updatedAddress?.state  && updatedAddress?.pincode && updatedAddress?.pincode.length === 6) {
    addressValidationStatus1 = true
  } 
  /** 
  else {
    addressValidationStatus1 = false
  }
  */
  const mergedAddress = {...updatedAddress,  addressValidationStatus: addressValidationStatus1 }
  return mergedAddress;
}

export const vetSelected = (dispatch, vet) => {
  return dispatch({
    type: "VET_SELECTED",
    payload: {
      vet: vet
    }
  });
};

export const addToCart = (dispatch, cartItem) => {
  return dispatch({
    type: "ADD_TO_CART",
    payload: {
      cartItem: cartItem
    }
  });
};

export const removeFromCart = (dispatch, cartItemId) => {
  return dispatch({
    type: "REMOVE_FROM_CART",
    payload: {
      cartItemId: cartItemId
    }
  });
};

export const clearCart = (dispatch) => {
  return dispatch({
    type: "CLEAR_CART"
  });
};

export const clearAddress = (dispatch) => {
  return dispatch({
    type: "CLEAR_ADDRESS"
  });
};

const CartProvider = ({ children }) => {
  const [persistedCartItems, setPersistedCartItems] = useLocalStorage(
    "cartItems",
    []
  );
  const [persistedAddress, setPersistedAddress] = useLocalStorage(
    "address",
    {}
  );
  const [persistedVet, setPersistedVet] = useLocalStorage(
    "vet",
    {}
  );
  const persistedCartState = {
    isCartOpen: false,
    items: persistedCartItems || [],
    address: persistedAddress || {},
    vet: persistedVet || {},

  };
  const [cartstate, dispatch] = useReducer(reducer, persistedCartState);
  useEffect(() => {
    setPersistedCartItems(cartstate?.items);
    setPersistedAddress(cartstate?.address);
    setPersistedVet(cartstate?.vet);
  }, [JSON.stringify(cartstate?.items), JSON.stringify(cartstate?.address), JSON.stringify(cartstate?.vet)]);
  return (
    <CartDispatchContext.Provider value={dispatch}>
      <CartStateContext.Provider value={cartstate}>
        {children}
      </CartStateContext.Provider>
    </CartDispatchContext.Provider>
  );
};

export default CartProvider;
