import {
    Link,
    Text
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import $ from 'jquery';
import BreedSearch from "./BreedSearch";
import {
    QuotesDispatchContext,
    QuotesStateContext,
    getQuotes,
    ageLabel
} from "../contexts/quotes";
import React, { useState, useContext } from 'react';

export default function PetBasicDetailsForQuote() {
    const { request } = useContext(QuotesStateContext);
    const [disabled, setDisabled] = useState(true);
    const [age, setAge] = useState(request.age);
    const [breed_id, setBreed_id] = useState(request.breed_id);
    const [breed_label, setBreed_label] = useState(request.breed_label);
    const [breed_group, setBreed_group] = useState(request.breed_group);

    const dispatch = useContext(QuotesDispatchContext);

    function selectAge(event) {
        setDisabled(true)
        setAge(event?.target?.value);
        getQuotes(dispatch, { 'age': event?.target?.value, 'breed_id': breed_id, 'breed_label': breed_label, 'breed_group': breed_group });
    }


    function selectBreed(selected) {
        //console.log('selectBreed called :' + selected)
        if (selected) {
            if (selected[0]) {
                setDisabled(true)
                setBreed_id(selected[0].id);
                setBreed_label(selected[0].label);
                setBreed_group(selected[0].group);
                getQuotes(dispatch, { 'age': age, 'breed_id': selected[0].id, 'breed_label': selected[0].label, 'breed_group': selected[0].group });
            } else {
                setBreed_id('f25a986e-d5f5-4190-8493-b824ba5e219b');
                setBreed_label('Indian Mongrel');
                setBreed_group('MIXED');
                //getQuotes(dispatch, { 'age': request.age, 'breed_id': request.breed_id, 'breed_label': request.breed_label, 'breed_group': request.breed_group });
            }

        }
        
    }

    const editPetDetails = () => {
        //$('.custom-select').prop('disabled', false);
        $('.custom-bgpet').removeClass('disabled-select');
        setDisabled(false);
    }

    return (
        <div className="wrapper-dog-details">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-md-11 col-10">
                        <div className="row no-gutters">
                            <div className="col-lg-6 dog-details">
                                <strong>AGE : </strong>
                                <div className="form-group custom-bgpet disabled-select mb-0">
                                    <select value={age} className="custom-select" onChange={(event) => selectAge(event)} disabled={disabled}>
                                        <option disabled>Select Pet Age</option>
                                        <option value={'A'} >{ageLabel.get('A')}</option>
                                        <option value={'B'} >{ageLabel.get('B')}</option>
                                        <option value={'C'} >{ageLabel.get('C')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6 dog-details dog-breed-bg">
                                <strong>BREED : </strong>
                                <div className="form-group custom-bgpet disabled-select mb-0">
                                    <BreedSearch disabled={disabled} onChange={(selected) => selectBreed(selected)} defaultInputValue={breed_label} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 col-2 edit-details-bg">
                        <Link href="#" className="editPetDetails" onClick={editPetDetails}><i className="icon-edit"></i></Link>
                    </div>
                </div>
                <div className="row no-gutters">
                <Text variation={'tertiary'} fontSize={'0.7rem'} as='p'>The options suggested herein are just out of permutation of various coverages under Digit Pet Insurance Policy UIN: IRDAN158RP0006V01202223 for customer convenience. For more details, please read and refer to policy terms and conditions carefully before enrollement.Note: Apart from the option chosen, your pet insurance premium will be calculated based on your dog’s age and breed.</Text>
                </div>
            </div>

        </div>
    );

}