// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CartStatus = {
  "OPEN": "OPEN",
  "PAYMENT_SUCCESS": "PAYMENT_SUCCESS",
  "PAYMENT_FAILED": "PAYMENT_FAILED"
};

const AgeBracket = {
  "A": "A",
  "B": "B",
  "C": "C",
  "D": "D",
  "E": "E",
  "X": "X"
};

const BreedGroup = {
  "MIXED": "MIXED",
  "SMALL": "SMALL",
  "MEDIUM": "MEDIUM",
  "LARGE": "LARGE",
  "EXLARGE": "EXLARGE"
};

const AddressType = {
  "HOME": "HOME",
  "WORK": "WORK",
  "OTHER": "OTHER"
};

const Plans = {
  "RED": "RED",
  "YELLOW": "YELLOW",
  "BLUE": "BLUE",
  "MICROCHIP": "MICROCHIP"
};

const { Cart, CartItems, Vets, ProposerVet, PremiumChart, Breed, ProposerUser, Location } = initSchema(schema);

export {
  Cart,
  CartItems,
  Vets,
  ProposerVet,
  PremiumChart,
  Breed,
  ProposerUser,
  CartStatus,
  AgeBracket,
  BreedGroup,
  AddressType,
  Plans,
  Location
};