import React, { useMemo } from "react";

import './App.css';
import {
  HeaderNew,
  Footer,
  Landing,
  Quote1,
  Checkout,
  Payment,
  FailedCheckout,
  Help
} from './custom-components';
import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import CartProvider from './contexts/cart'
import QuotesProvider from './contexts/quotes'
import { Worker } from '@react-pdf-viewer/core';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
//import { DataStore } from '@aws-amplify/datastore';
//import packageJson from '../package.json';
//const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];

Amplify.configure({ ...awsconfig });
/*
DataStore.configure({
  errorHandler: (error) => {
    console.warn('Unrecoverable error', { error });
  },
});
*/

function useQuery() {
  const { search } = useLocation();
  //console.log('search--->' +JSON.stringify(search));
  return useMemo(() => new URLSearchParams(search)?.get('encp'), [search]);
}

export default function App(props) {
  let query = useQuery();
  //console.log('query--->' +JSON.stringify(query));
  return (
    <div>
      <QuotesProvider>
        <CartProvider>
          <HeaderNew />
          <Routes>
            <Route exact path="/" element={
              <div>
                <Landing key={1}></Landing>
              </div>
            }> </Route>
            <Route path='/quote' element={<Quote1 />}></Route>
            <Route path='/help' element={<Help />}></Route>
            <Route path='/checkout' element={<Checkout />}></Route>
            <Route path='/failedcheckout' element={<FailedCheckout />}></Route>
            <Route path='/paymentstatus' element={<Payment encp={query} />}></Route>
          </Routes>
          <Footer />

        </CartProvider>
      </QuotesProvider>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      </Worker>
    </div>
  );
}
