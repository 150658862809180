import React, { useContext, useEffect } from "react";
import {
    View,
    Flex,
    Collection,
    Card,
    useTheme
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import QuoteLine from './QuoteLine';
import PetBasicDetailsForQuote from './PetBasicDetailsForQuote';
import PlanFeatures from './PlanFeatures';
import {
    QuotesStateContext,
    QuotesDispatchContext,
    getQuotes,
} from "../contexts/quotes";


export default function Quote1() {
    const { tokens } = useTheme();
    const { quotes, request } = useContext(QuotesStateContext);
    const dispatch = useContext(QuotesDispatchContext);


    useEffect(() => {

        getQuotes(dispatch, { 'age': request?.age, 'breed_id': request?.breed_id, 'breed_label': request?.breed_label, 'breed_group': request?.breed_group });
    }, [dispatch, request?.age, request?.breed_id, request?.breed_label, request?.breed_group]);

    return (
        <Card variation={'outlined'}>
            <View backgroundColor={tokens.colors.background.primary}>
                <Flex rowGap={tokens.space.small}
                    direction={'column'} >
                    <PetBasicDetailsForQuote key={'999999_1'} />
                    <Collection
                        type="grid"
                        items={quotes ? quotes : []}
                        isPaginated={quotes ? quotes?.length > 12 : false}
                        itemsPerPage={12}
                        searchNoResultsFound={
                            <Card padding={'0px'} key={'999999_2'}>
                                <PlanFeatures key={'9999999'} msg={'No Quotes matching the criteria found , please select age group and breed'}></PlanFeatures>
                            </Card>
                        }
                    >
                        {(quote, index) => (
                            <div key={index + '-' + quote?.id + '-DIV'}>
                                <QuoteLine key={index + '-' + quote?.id} quote={{ key: quote?.id + '_' + index, id: quote?.id, plan: quote?.plan, sum_insured: quote?.sum_insured, premium_incl_gst: quote?.premium_incl_gst, premium_excl_gst:quote?.premium_excl_gst,age: request?.age, breed: request?.breed_label ,breed_group:request?.breed_group , microchip:false , quantity:1 }} />
                            </div>
                        )

                        }
                    </Collection>
                </Flex>
            </View>
        </Card>
    );

}