import React from "react";
import {
  Card,
} from '@aws-amplify/ui-react';
//import PaymentAddressDetails from "./PaymentAddressDetails";
//import PaymentCartDetails from "./PaymentCartDetails";
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import Modal from 'react-modal';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
Amplify.configure(awsconfig);


export default function FailedCheckout(props) {
 
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  Modal.setAppElement('#root');

  return (
    <Card>
      <div className="page-process-wrapper border-top">
        <div className="container">
          <div className="row no-gutters">
            <div className="col">
              {
                 (
                  <div>
                    <h2>Thank you for interest in buying medical cover for your pets. Checkout process has failed. Please retry</h2>
                    <div className="clearfix"></div>
                    <div className="page-process-bg">
                      <div className="progress-line"></div>
                      <div className="progress-complete-line" style={{ width: '100%' }}></div>
                      <div className="progress-content user-details progress-complete">
                        <a href="#">
                          <div className="content-icon"><i className="icon-brochure"></i></div>
                          Contact customer service; if you are getting this error repeatedly 
                        </a>
                      </div>
                      <div className="progress-content pet-details progress-complete">
                        <a href="pet-details.html">
                          <div className="content-icon"><i className="icon-summary"></i></div>
                          Retry checkout by going back to your Cart ( Top right bag icon)
                        </a>
                      </div>
                      <div className="progress-content summary progress-complete">
                        <a href="summary.html">
                          <div className="content-icon"><i className="icon-user-details"></i></div>
                          New order id will be generated
                        </a>
                      </div>
                      <div className="progress-content payment-gateway progress-complete">
                        <a href="payment-gateway.html">
                          <div className="content-icon"><i className="icon-payment-gateway"></i></div>
                          After successful payment application form(s) will be emailed
                        </a>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
      <Modal
        isOpen={false}
        style={customStyles}
        contentLabel="Pawtect Payment"
        ariaHideApp={false}
      >
      </Modal>
    </Card>
  );
}


