import React, { useReducer, createContext, useContext } from "react";
import { API, graphqlOperation } from 'aws-amplify';
import { listPremiumCharts } from '../graphql/queries';

const initialState = {
  quotes: {},
  request: { age: 'A', breed_id: '', breed_label: '', breed_group: '' },
  isLoading: false,
  isLoaded: false
};

export const QuotesStateContext = createContext();

export const useQuotesStateContext = () => {
  return useContext(QuotesStateContext);
}
export const QuotesDispatchContext = createContext();

export const ageLabel = new Map();
ageLabel.set('A', '12 weeks - 1 Year');
ageLabel.set('B', '1 - 3 years');
ageLabel.set('C', '3 - 6 years');
ageLabel.set('D', '6 - 8 years');
//ageLabel.set('E','8 - 10 years');
ageLabel.set('X', 'Pet Identification Chip');


const reducer = (state, action) => {
  console.log(action.type);
  console.log(action.payload);
  console.log(JSON.stringify(state));
  switch (action?.type) {
    case "GET_QUOTES_REQUEST":
      let newstate = {
        ...state,
        request: action?.payload?.request,
        isLoading: true,
        isLoaded: false
      };
      console.log(JSON.stringify(newstate));
      return newstate;
    case "GET_QUOTES_SUCCESS":
      //console.log(action?.payload?.quotes);
      return {
        ...state,
        request: action?.payload?.request,
        isLoading: false,
        isLoaded: true,
        quotes: action?.payload?.quotes?.listPremiumCharts?.items?.sort((a, b) => a?.premium_incl_gst - b?.premium_incl_gst)
      };
    case "GET_QUOTES_FAILURE":
      return {
        ...state,
        request: action?.payload?.request,
        quotes: null,
        isLoading: false,
        isLoaded: false
      };
    default:
      return state;
  }
};

const QuotesProvider = ({ children }) => {
  const [quotestate, dispatch] = useReducer(reducer, initialState);
  //console.log(JSON.stringify(quotestate));
  return (
    <QuotesDispatchContext.Provider value={dispatch}>
      <QuotesStateContext.Provider value={quotestate}>
        {children}
      </QuotesStateContext.Provider>
    </QuotesDispatchContext.Provider>
  );
};

export function validateRequest(request) {
  console.log('validateRequest called :' + request?.age);
  console.log('validateRequest called :' + request?.breed_group);
};

export const getQuotes = (dispatch, request) => {
  validateRequest(request);
  dispatch({
    type: "GET_QUOTES_REQUEST",
    payload: {
      request: request
    }
  });
  getQuotesGraphQL(dispatch, request);
};

export const updateQuoteCriteria = (dispatch, request) => {
  validateRequest(request);
  dispatch({
    type: "GET_QUOTES_REQUEST",
    payload: {
      request: request
    }
  });
};

export const getQuotesGraphQL = (dispatch , request) => {
  let filter = { age_bracket: { eq: request?.age }, breed_group: { eq: request?.breed_group } }
  API.graphql(graphqlOperation(listPremiumCharts, { filter: filter, limit: 500 }))
    .then((response) => {
      dispatch({
        type: "GET_QUOTES_SUCCESS",
        payload: {
          request: request,
          quotes: response?.data

        }
      });
    })
    .catch((error) => {
      console.error(JSON.stringify(error));
      dispatch({
        type: "GET_QUOTES_FAILURE",
        payload: {
          request: request
        }
      });
    });

};

export default QuotesProvider;
