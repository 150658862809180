import React, { useContext, useState } from "react";
import {
  Grid,
  View,
  Card,
  Loader,
  Text,
} from '@aws-amplify/ui-react';
import AddressInputOTP from "./AddressInputOTP";
import AddressInput from "./AddressInput";
import CartUI from "./CartUI";
import '@aws-amplify/ui-react/styles.css';
import { CartStatus, BreedGroup, AgeBracket, Plans } from '../models';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import {
  CartStateContext,
  //checkoutInProgress,
} from "../contexts/cart";
import Modal from 'react-modal';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { API, graphqlOperation } from 'aws-amplify';
import { createCart, createProposerUser, createCartItems, createProposerVet } from '../graphql/mutations';

Amplify.configure({ ...awsconfig });

const REST_API_ENDPOINT = 'https://8y9uwbp88d.execute-api.ap-southeast-1.amazonaws.com/staging/pawtectcheckout-staging';


export default function Checkout(props) {
  const enableOTP = false;
  const { items, address, vet, tncAgreed } = useContext(CartStateContext);
  const [showDialog, setShowDialog] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [src, setSrc] = useState({ src: '/failedcheckout' });
  const [isLoading, setIsLoading] = useState(false);
  //const [cartID, setCartID] = useState();

  async function handleCheckout() {
    setShowDialog(true);
    setShowProgress(true);
    setIsLoading(true);
    let cartID;
    try {
      cartID = await prepareCart();
      //console.log('Calling fetch:CartID:' + JSON.stringify(cartID));
    } catch (error) {
      //console.log(error);
      setSrc('/failedcheckout');
      window.location.href = '/failedcheckout';
      setShowProgress(false);
    }

    if (cartID) {
      await fetch(REST_API_ENDPOINT + '?cartId=' + cartID)
        .then((response) => response.json().then((data) => {
          setSrc(data);
          //console.log('handleCheckout-->' + JSON.stringify(data));
          window.location.href = data?.src ? data?.src : '/failedcheckout';
          setShowProgress(false);
          setTimeout(setShowDialog(false),5000);
          
        }))
        .catch(error => {
          //console.log(error);
          setSrc('/failedcheckout');
          window.location.href = '/failedcheckout';
          setShowProgress(false);
          setShowDialog(false)
      })
    }
  }

  async function prepareCart() {
    const total_amount = (items.reduce((prev, curr) => { return prev = prev + curr?.quantity * curr?.premium_incl_gst }, 0));
    const sgst = (address?.state === 'Maharashtra' ? true : false)
    let proposerVet = null;
    if (vet?.id) {
      proposerVet = { vet_name: vet?.name, vet_id: vet?.id }
      try {
        proposerVet = await API.graphql(graphqlOperation(createProposerVet, { input: proposerVet, authMode: 'aws_api_key' }));
        if (proposerVet?.errors) {
          console.log(JSON.stringify(proposerVet?.errors));
          throw (proposerVet?.errors[0]);
        }

      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
      console.log("proposerVet-->" + JSON.stringify(proposerVet));
    }

    let proposerUser = {
      "fullName": address?.name,
      "mobile": address?.mobile,
      "email": address?.email,
      "pan": '',
      "addressLine1": address?.addressLine,
      "addressLine2": address?.area,
      "city": address?.city,
      "state": address?.state,
      "pincode": address?.pincode,
      "countryCode": "IN",
    }
    try {

      proposerUser = await API.graphql(graphqlOperation(createProposerUser, { input: proposerUser, authMode: 'aws_api_key' }));
      if (proposerUser?.errors) {
        console.log(JSON.stringify(proposerUser?.errors));
        throw (proposerUser?.errors[0]);
      }

    } catch (error) {
      console.log(JSON.stringify(error));
      throw error;
    }
    console.log("proposerUser-->" + JSON.stringify(proposerUser));

    let cart = {
      "status": CartStatus.OPEN,
      "total_amount": total_amount,
      "cartProposerUserId": proposerUser?.data?.createProposerUser?.id,
      "cartProposerVetId": proposerVet?.data?.createProposerVet?.id,
      "tncagreed": tncAgreed,
    }
    try {
      cart = await API.graphql(graphqlOperation(createCart, { input: cart, authMode: 'aws_api_key' }));
      if (cart?.errors) {
        //console.log(JSON.stringify(cart?.errors));
        throw cart?.errors[0];
      }

    } catch (error) {
      console.log(JSON.stringify(error));
      throw error;
    }

    console.log("cart-->" + JSON.stringify(cart));

    await items.map((item) => {
      let cartItem =
      {
        "age": AgeBracket[item?.age],
        "breed_group": BreedGroup[item?.breed_group],
        "breed": item?.breed,
        "premium_excl_gst": item?.premium_excl_gst?.toFixed(2),
        "cgst": +(item?.premium_excl_gst * 0.09)?.toFixed(2),
        "sgst": sgst ? +(item?.premium_excl_gst * 0.09)?.toFixed(2) : 0,
        "igst": sgst ? 0 : +(item?.premium_excl_gst * 0.09)?.toFixed(2),
        "microchip": item?.microchip,
        "microchip_excl_gst": 0?.toFixed(2),
        "plan": Plans[item?.plan],
        "sum_insured": item?.sum_insured,
        "quantity":item?.quantity,
        "cartID": cart?.data?.createCart?.id
      };
      console.log("cartItem-->" + JSON.stringify(cartItem));
      try {
        cartItem = API.graphql(graphqlOperation(createCartItems, { input: cartItem, authMode: 'aws_api_key' }));
        if (cartItem?.errors) {
          console.log(JSON.stringify(cartItem?.errors));
          throw cartItem?.errors[0];
        }

      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
      return cartItem;
    });
    return cart?.data?.createCart?.id;
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  Modal.setAppElement('#root');

  return (
    <Card>
      <Grid
        alignItems="flex-start"
        alignContent="flex-start"
        templateColumns={{ base: '1fr', large: '1fr 2fr' }}
        templateRows={{ base: '2fr', large: '1fr' }}
        columnGap={'20px'}
      >
        <View columnSpan={1}>
          {enableOTP ? (<AddressInputOTP isLoading={isLoading} />) : (<AddressInput isLoading={isLoading} />)}
        </View>
        <View columnSpan={1}>
          <CartUI columnSpan={1}
            handleProceedCheckout={handleCheckout}
            isLoading={isLoading}
          >
          </CartUI>
        </View>
      </Grid>
      <Modal
        isOpen={showDialog}
        style={customStyles}
        contentLabel="Pawtect Payment"
        ariaHideApp={false}
      >{

          showDialog && showProgress ? (
            <Card>
              <Text variation={'tertiary'}>We are redirecting you to payment gateway , please do not refresh or go back</Text>
              <br />
              <Loader
                size="large"
                variation="linear"
              />
            </Card>
          ) : (<div></div>       
          )
        }
      </Modal>
    </Card>
  );
}


