import { useState, useEffect, useContext, useRef  } from "react";
//import { useNavigate } from "react-router-dom";
import {
    View,
    Text
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {
    Button,
} from '@aws-amplify/ui-react';

import { CartDispatchContext, addToCart } from "../contexts/cart";
import { CSSTransition } from 'react-transition-group';

//import CartAnimationImg from "./CartAnimationImg";

export default function QuoteLine({ quote }) {
    const containerRef = useRef();
    const [quotelineid, setQuoteLineId] = useState('');
    const [isAdded, setIsAdded] = useState(false);
    const dispatch = useContext(CartDispatchContext);
    //let cordinates = containerRef?.current?.getBoundingClientRect();
    //const navigate = useNavigate();

    const redCover = (quote?.plan === 'RED') ? "bg-dark-gray" : "";
    const yellowCover = (quote?.plan === 'YELLOW') ? "bg-dark-gray" : "";



    useEffect(() => {
        setQuoteLineId(quote?.id);

    }, [quote?.id]);

    const handleAddToCart = () => {
        //let cordinates = containerRef?.current?.getBoundingClientRect();
        const product = { ...quote, quantity: 1};
        addToCart(dispatch, product);
        setIsAdded(true);
        setTimeout(() => {
            setIsAdded(false);
        }, 3500);
        //navigate('/checkout');
    };

    return (
        <View key={quotelineid}>
            <div className="quotation-bg" ref={containerRef} key={quotelineid +'_1'}>
                {quote?.plan === 'RED' && (
                    <div className="ribbon-head-bg color-red" key={quotelineid +'_2'}>
                        RED<br className="d-none d-md-block" />
                        <i className="icon-ribbon" key={quotelineid +'_3'}/>
                    </div>
                )
                }
                {quote?.plan === 'YELLOW' && (
                    <div className="ribbon-head-bg color-yellow" key={quotelineid +'_4'}>
                        YELLOW<br className="d-none d-md-block" />
                        <i className="icon-ribbon" key={quotelineid +'_5'} />
                    </div>
                )
                }
                {quote?.plan === 'BLUE' && (
                    <div className="ribbon-head-bg color-blue" key={quotelineid +'_6'}>
                        BLUE<br className="d-none d-md-block" />
                        <i className="icon-ribbon" key={quotelineid +'_7'} />
                    </div>
                )
                }
                <div className="quotation-details-bg" fontSize="0.8rem" key={quotelineid +'_8'}>
                    <div className="dog-details" key={quotelineid +'_9'}>
                        <p key={quotelineid +'_10'}>Sum Insured : <span>₹{(quote?.sum_insured)?.toLocaleString('en-IN')}</span></p>
                    </div>

                    <div className="dog-details-bg02" key={quotelineid +'_11'}>
                        <div className="dog-details02" key={quotelineid +'_12'}>
                            <i className="icon-major-illness" key={quotelineid +'_13'} />
                            <span key={quotelineid +'_16'}>Specific Illness</span>
                        </div>
                        <div className="dog-details02" key={quotelineid +'_14'}>
                            <i className={"icon-accidental-injuries " + yellowCover} key={quotelineid +'_15'} />
                            <span key={quotelineid +'_17'}>Critical Illness</span>
                        </div>
                        <div className="dog-details02" key={quotelineid +'_18'}>
                            <i className={"icon-plan-features01 " + redCover} key={quotelineid +'_19'}/>
                            <span key={quotelineid +'_20'}>Out Patient Department Visits</span>
                        </div>
                        <div className="dog-details02" key={quotelineid +'_21'}>
                            <i className="icon-third-party" key={quotelineid +'_22'} />
                            <span key={quotelineid +'_23'}>Third Party Liabilities</span>
                        </div>
                    </div>
                </div>

                <div className="dog-price-details-bg" key={quotelineid +'_24'}>
                    <div className="dog-price" key={quotelineid +'_25'}>₹{(quote?.premium_incl_gst)?.toLocaleString('en-IN')}</div>
                    <span>Inclusive of GST</span>
                    <Button variation="primary" key={quotelineid +'_26'}
                        onClick={handleAddToCart}
                    >
                        Enroll Now
                    </Button>
                    <CSSTransition key={quotelineid +'_27'}
                        in={isAdded}
                        timeout={300}
                        classNames='my-element' // IMP!
                        unmountOnExit
                    >
                       <Text key={quotelineid +'_28'} padding={'5px'} color={'orange'} fontSize={'small'}> Add plan to become member on top right before checkout </Text>
                    </CSSTransition>
                </div>

            </div>
            {/*/Section*/}
        </View>
    );


}