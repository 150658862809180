import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { AmplifyProvider, ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify, AuthModeStrategyType } from 'aws-amplify';

import awsconfig from './aws-exports';

import "@aws-amplify/ui-react/styles.css";
import { studioTheme } from "./ui-components";
import {
  BrowserRouter
} from 'react-router-dom';

//Amplify.configure(awsconfig);
Amplify.configure({
  ...awsconfig,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
<base href="/" />
root.render(
  // <React.StrictMode>
  <AmplifyProvider>
    <ThemeProvider theme={studioTheme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>

  </AmplifyProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
