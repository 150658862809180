import { Auth } from 'aws-amplify';
import React, { useContext, useState, useEffect } from "react";
import {
    CartStateContext,
    CartDispatchContext,
    toggleCartPopup
} from "../contexts/cart";
import CartPreview from "./CartPreview";
import {
    Link,
} from 'react-router-dom';
import {
    Image,
    Badge,
    Grid,
    Card,
    View,
    Heading

} from '@aws-amplify/ui-react';


const HeaderNew = (props) => {

    const { items: cartItems } = useContext(CartStateContext);
    const cartDispatch = useContext(CartDispatchContext);


    const handleCartButton = (event) => {
        event.preventDefault();
        return toggleCartPopup(cartDispatch);
    }

    const [state, setState] = useState({});

    useEffect(() => {

        Auth.currentUserInfo().then(currentUser => {
            if (currentUser && currentUser.attributes) {
                setState(state => {
                    state.currentUser = currentUser;
                    state.signedIn = true;
                    state.email = currentUser.attributes.email;
                    state.name = currentUser.attributes.name;
                    return state;
                })
            }
        })
    }
    );

    return (
        <View>
            <Card paddingTop={'20px'} paddingBottom={'5px'}>
                {/*/HEADER*/}
                <Grid templateColumns={'2fr 6fr 1fr'} padding={'0px'}>
                    <Link to="/"><img src="img/vetina-LOGO-230-px.png" alt="Pawtect India" width="230px" style={{ 'justifySelf': 'start' }} /></Link>
                    {/*<Link to="https://www.godigit.com"><img src="img/digit.svg" alt="Go Digit"  style={{ 'justifySelf': 'start' }} width="150px" height="50px" /></Link> */}
                    <Link to="/help" style={{ 'justifySelf': 'end' }} >
                        <h2>Help</h2>
                    </Link>
                    <Link to="#" onClick={handleCartButton} style={{ 'justifySelf': 'end' }}>
                        <Image
                            src="img/bag.png"
                            alt="Cart"
                            width={'50px'}
                        ></Image>
                        {cartItems ? (
                            <Badge variation={'success'}>{cartItems.map((item) => item.quantity).reduce((prev, curr) => { return prev = prev + curr }, 0)}</Badge>
                        ) : (
                            <Badge variation={'warning'}>{"0"}</Badge>
                        )}
                    </Link>
                </Grid>
                <CartPreview />
            </Card>

        </View>
    );
};
export default HeaderNew;
