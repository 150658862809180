import $ from 'jquery';
import BreedSearch from "./BreedSearch";
import {
  Link,
} from 'react-router-dom';
import {
  Grid,
  Button,
  Divider,
  CheckboxField,
  View,
  Text,
} from '@aws-amplify/ui-react';
import {
  QuotesDispatchContext,
  QuotesStateContext,
  ageLabel,
  updateQuoteCriteria
} from "../contexts/quotes";
import React, { useState, useContext, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import {
  CartStateContext,
  vetinaFamilyClicked,
  CartDispatchContext
} from "../contexts/cart";

import Modal from 'react-modal';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function Landing(props) {
  const { request } = useContext(QuotesStateContext);
  const [age, setAge] = useState(request?.age);
  const { vetinaFamily } = useContext(CartStateContext);
  const [breed_id, setBreed_id] = useState(request?.breed_id);
  const [breed_label, setBreed_label] = useState(request?.breed_label);
  const [breed_group, setBreed_group] = useState(request?.breed_group);
  const dispatch = useContext(QuotesDispatchContext);
  const [showPWDialog, setShowPWDialog] = useState(false);
  const [showBRDialog, setShowBRDialog] = useState(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const dispatchCart = useContext(CartDispatchContext);

  useEffect(() => {
    if (showPWDialog || showBRDialog) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showPWDialog, showBRDialog])

  function handleShowPolicyWording() {
    setShowPWDialog(true);
    setShowBRDialog(false);
    //openDialog(dispatch, true);
  }

  function handleShowBrochure() {
    setShowBRDialog(true);
    setShowPWDialog(false);
    //openDialog(dispatch, true);
  }


  function closeDialog() {
    setShowBRDialog(false);
    setShowPWDialog(false);
    //openDialog(dispatch, false);
  }

  useEffect(() => {
    localStorage.clear();
    Amplify.DataStore.clear();

  }, []);

  function selectBreed(selected) {
    console.log("selected:" + selected);
    if (selected) {
      if (selected[0]) {
        console.log("selected:" + selected[0].label);
        console.log("selected:" + selected[0].group);
        setBreed_id(selected[0].id);
        setBreed_label(selected[0].label);
        setBreed_group(selected[0].group);
      }
      else {
        setBreed_id('f25a986e-d5f5-4190-8493-b824ba5e219b');
        setBreed_label('Indian Mongrel');
        setBreed_group('MIXED');
      }

    }
  }

  function showQuoteDropdown() {
    if ($(".get-quote-section").hasClass("show")) {
      $(".get-quote-section").animate({ right: '-300px' }).removeClass("show");
    } else {
      $(".get-quote-section").animate({ right: '0px' }).addClass("show");
    }

    if ($(".get-quote-section").hasClass("show")) {
      $(".btn-get-quote").text("X").css("left", "-43px");
    } else {
      $(".btn-get-quote").text("GET QUOTE").css("left", "-138px");
    }

  }

  const handleVetinaFamilyClicked = (checked) => {
    if (!props?.isLoading) {
      vetinaFamilyClicked(dispatchCart, checked);
    }
  }

  const handleQuotes = () => {
    console.log("vetinaFamily:" + vetinaFamily);
    //if (vetinaFamily) {
    updateQuoteCriteria(dispatch, { 'age': age, 'breed_id': breed_id, 'breed_label': breed_label, 'breed_group': breed_group })
    //}
  }

  const dialogStyle = {
    overlay: { zIndex: 1000 },
    content: {
      border: '0',
      borderRadius: '4px',
      bottom: 'auto',
      //height: '600px',  // set height
      left: '1%',
      padding: '2rem',
      //position: 'fixed',
      right: 'auto',
      top: '1%', // start from center
      //transform: 'translate(-50%,-' + offsetPx + ')', // adjust top "up" based on height
      width: '80%',
      //maxWidth: '40rem'
      //overflow: 'hidden'
    }
  };



  return (
    <div>
      {/*CSS*/}
      {/*SECTION*/}
      <Divider></Divider>
      <div className="wrapper-banner">
        <div className="container">
          <div className="row">
            <div className="col-6 align-self-end" height="150px">
              <h1>Medical Cover for your pet<br />
                <span>Your love and care, <br />
                  express your feelings!</span></h1>
               
              <Link style={{ margin: '2%  0%  2% 0%' }} onClick={handleShowBrochure} href='#'><i className="icon-brochure" />Click to explore brochure..</Link>
              <Text className="float-left" style={{ margin: '2%  0%  2% 0%' }} >*Vetina is acting as a Group Manager to enable customers to enroll for insurance cover arranged through Go Digit General Insurance Limited.</Text>
              <Link className="float-left" onClick={handleShowPolicyWording} ><i className="icon-brochure" /> Digit Pet Insurance Policy Wordings - UIN: IRDAN158RP0006V01202223</Link>
            </div>
            <div className="col-6">
              <img className="float-right" src="img/home-banner.png" alt="Pawtect" />
            </div>
          </div>
        </div>
      </div>
      <div>
        {/*SECTION*/}
        <div className="wrapper-pawtect-Plan">
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <h2>Choose from three medical care options Red, Yellow & Blue Ribbons</h2>
                <Text fontSize={'0.75rem'} as='p'>The options suggested herein are just out of permutation of various coverages under Digit Pet Insurance Policy UIN: IRDAN158RP0006V01202223 for customer convenience. For more details, please read and refer to policy terms and conditions carefully.Note: Apart from the option chosen, your pet insurance premium will be calculated based on your dog’s age and breed.</Text>
                <h3>Give your furry <br className="d-none d-md-block" />
                  family members the best <br className="d-none d-md-block" />
                  healthcare they deserve</h3>
                <p>without unforeseen medical expenses burning a hole in your pocket. At a nominal premium, Digit Pet Insurance Policy gives you the assurance of medically covering your pets so you concentrate on their recovery instead of worrying about the cost.</p>
              </div>
              <img className="img-pawtect-plan" src="img/img-pawtect-plan.png" alt="Pawtect Medicare Options" />
            </div>
          </div>
        </div>
        {/*SECTION*/}
        <div className="wrapper-plan-features">
          <div className="container">
            <div className="row no-gutters">
              <div className="col">
                <i className="icon-plan-features01 pl-0" />
                <p className="pl-0 border-0">We know you take care of them the most, but in all their fun and
                  play, accidents happen and we’re here to cover for just that! and to ensure your furry baby gets the best treatment and that
                  quality healthcare is never too far-fetched!</p>
              </div>
              <div className="col">
                <i className="icon-plan-features02" />
                <p>Freedom to choose the best out of 4 covers for your pet covering Critical Illness, Specific Illness, OPD cover and Third Party Liability</p>
              </div>
              <div className="col">
                <i className="icon-plan-features03" />
                <p>Enroll pets from the age of 12 weeks upto 6 years, and renew until your pet is 10 years old, Medical cover can be transferred to the new owner during cover period</p>
              </div>
              <div className="col">
                <i className="icon-plan-features04" />
                <p>Freedom to choose the four sum insured options meeting your budget and protection needs starting from ₹40,000 upto ₹1,50,000</p>
              </div>
              <div className="col">
                <i className="icon-plan-features05" />
                <p>Initial waiting period 30 days for illness. Pre-existing diseases covered after 4 years waiting. 20% co-pay on OPD claims . 2 years specific illness waiting and Third Party Liabilities upto opted sum insured</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="get-quote-section" id="get-quote-section">
        <div className="btn-get-quote" onClick={showQuoteDropdown} >GET QUOTE</div>
        <div id="get-quote-form">
          <h2>GET QUOTE</h2>
          <form id="quoteform">
            <div className="form-group">
              <span className="label label-default">Select Age</span>
              <select className="custom-select" value={age} onChange={(e) => setAge(e.target.value)}>
                <option disabled>Select Pet Age</option>
                <option value={'A'} >{ageLabel.get('A')}</option>
                <option value={'B'} >{ageLabel.get('B')}</option>
                <option value={'C'} >{ageLabel.get('C')}</option>
              </select>
            </div>
            <div className="form-group">
              <div >
                <span className="label label-default">Select Breed</span>
                <BreedSearch onChange={(selected) => selectBreed(selected)} defaultInputValue={breed_label} />
                <br />
                {!breed_id && (
                  <p className="alert alert-warning"> {"Please search and select pet's breed"} </p>
                )}
              </div>
              <Grid
                templateColumns={{ base: '0.25fr 7.75fr', large: '0.25fr 7.75fr' }}
                columnGap={{ base: '10px', large: '10px' }}
                rowGap={{ base: '10px', large: '10px' }}
              >
                <CheckboxField
                  checked={vetinaFamily ? vetinaFamily : false}
                  size={'large'}
                  value={vetinaFamily ? true : false}
                  onChange={(e) => handleVetinaFamilyClicked(e?.target?.checked)}
                  isDisabled={props?.isLoading}
                  id='landingChekbox'
                 /* border={'1px solid black'} */
                  borderRadius={'20%'}
                  backgroundColor={'white'}                 ></CheckboxField>
                <View>
                  <Text variation={'tertiary'} fontSize={'0.9rem'} color={"white"} as='span'>By clicking 'QUOTE', you agree to join Vetina family and receive our newsletters, communications & promotional offers.</Text>
                </View>
              </Grid>
            </div>
            {
              vetinaFamily ? <Link to="/quote" className="btn btn-submit" onClick={handleQuotes}>QUOTE</Link> : <Link href="/#" className="btn btn-submit" onClick={handleQuotes}>QUOTE</Link>
            }

          </form>
        </div>
      </div>
      <Modal
        isOpen={showBRDialog || showPWDialog}
        ariaHideApp={false}
        //preventScroll={true}
        style={dialogStyle}
      >
        <Grid rowGap={'10px'} alignItems={'end'}>
          <Button
            variation="primary"
            width={'150px'}
            style={{ 'justifySelf': 'end', background: '#20bf6b' }}
            onClick={closeDialog}
          >
            Close
          </Button>
          <div style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '500px',
          }}>
            <Viewer
              fileUrl={showBRDialog ? "pdf/Digit_Pet_Insurance_Brochure.pdf" : "pdf/Digit_Pet_Insurance_Policy_Wordings.pdf"}
              plugins={[defaultLayoutPluginInstance]}
            //defaultScale={SpecialZoomLevel.PageFit}
            />
          </div>
        </Grid>
      </Modal>
    </div>

  );
}

