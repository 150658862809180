import * as React from "react";
import '@aws-amplify/ui-react/styles.css';

import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { Authenticator, withAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import awsExports from '../aws-exports';
import Landing from "./Landing";

import {
    Flex,
    View,
    Card,
    Heading,
    Image,
    Text,
    Button,
    Link,
    useTheme
} from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);
Amplify.configure(awsExports);


export default function Login() {
    const { tokens } = useTheme();

    const components = {
        SignIn: {
            Header() {
                const { tokens } = useTheme();

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        <Image
                            alt="Pawtect logo"
                            src="/img/xlogo.png"
                            width="150px"
                            height="50px"
                        />
                    </Heading>
                );
            },
            Footer() {
                const { toResetPassword } = useAuthenticator();

                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toResetPassword}
                            size="small"
                            variation="link"
                        >
                            Reset Password
                        </Button>
                    </View>
                );
            },
        },

        SignUp: {
            Header() {
                const { tokens } = useTheme();

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Create a new account
                    </Heading>
                );
            },
            Footer() {
                const { toSignIn } = useAuthenticator();

                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toSignIn}
                            size="small"
                            variation="link"
                        >
                            Back to Sign In
                        </Button>
                    </View>
                );
            },
        },
        ConfirmSignUp: {
            Header() {
                const { tokens } = useTheme();
                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Enter Information:
                    </Heading>
                );
            },
            Footer() {
                return <Text>Footer Information</Text>;
            },
        },
        SetupTOTP: {
            Header() {
                const { tokens } = useTheme();
                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Enter Information:
                    </Heading>
                );
            },
            Footer() {
                return <Text>Footer Information</Text>;
            },
        },
        ConfirmSignIn: {
            Header() {
                const { tokens } = useTheme();
                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Enter Information:
                    </Heading>
                );
            },
            Footer() {
                return <Text>Footer Information</Text>;
            },
        },
        ResetPassword: {
            Header() {
                const { tokens } = useTheme();
                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Enter Information:
                    </Heading>
                );
            },
            Footer() {
                return <Text>Footer Information</Text>;
            },
        },
        ConfirmResetPassword: {
            Header() {
                const { tokens } = useTheme();
                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Enter Information:
                    </Heading>
                );
            },
            Footer() {
                return <Text>Footer Information</Text>;
            },
        },
    };

    const formFields = {
        signIn: {
            username: {
                placeholder: 'Enter your email',
            },
        },
        signUp: {
            password: {
                label: 'Password:',
                placeholder: 'Enter your Password:',
                isRequired: false,
                order: 2,
            },
            confirm_password: {
                label: 'Confirm Password:',
                order: 1,
            },
        },
        forceNewPassword: {
            password: {
                placeholder: 'Enter your Password:',
            },
        },
        resetPassword: {
            username: {
                placeholder: 'Enter your email:',
            },
        },
        confirmResetPassword: {
            confirmation_code: {
                placeholder: 'Enter your Confirmation Code:',
                label: 'New Label',
                isRequired: false,
            },
            confirm_password: {
                placeholder: 'Enter your Password Please:',
            },
        },
        setupTOTP: {
            QR: {
                totpIssuer: 'test issuer',
                totpUsername: 'amplify_qr_test_user',
            },
            confirmation_code: {
                label: 'New Label',
                placeholder: 'Enter your Confirmation Code:',
                isRequired: false,
            },
        },
        confirmSignIn: {
            confirmation_code: {
                label: 'New Label',
                placeholder: 'Enter your Confirmation Code:',
                isRequired: false,
            },
        },
    };

    return (
                <Card padding={tokens.space.large}>
                    <Authenticator >
                        {({ signOut }) => <button onClick={signOut}>Sign out</button>}
                    </Authenticator>
                </Card>
    );

}

