import { useTheme } from "@aws-amplify/ui-react";
import * as React from "react";

export default function PlanFeatures(props) {
  const { tokens } = useTheme();

  return (
    <div>
      {/*SECTION*/}
      <div className="wrapper-pawtect-Plan">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <h2 color={tokens.colors.red}>{props?.msg}</h2>
              <h3>Give your furry <br className="d-none d-md-block" />
                family members the best <br className="d-none d-md-block" />
                healthcare they deserve</h3>
              <p>without unforeseen medical expenses burning a hole in your pocket. At a nominal premium, Digit Pet Insurance Policy gives you the assurance of medically covering your pets so you concentrate on their recovery instead of worrying about the cost.</p>
            </div>
            <img className="img-pawtect-plan" src="img/img-pawtect-plan.png" alt="Pawtect Plans" />
          </div>
        </div>
      </div>
      {/*SECTION*/}
      <div className="wrapper-plan-features">
        <div className="container">
          <div className="row no-gutters">
            <div className="col">
              <i className="icon-plan-features01 pl-0" />
              <p className="pl-0 border-0">We know you take care of them the most, but in all their fun and
                play, accidents happen and we’re here to cover for just that! and to ensure your furry baby gets the best treatment and that
                quality healthcare is never too far-fetched!</p>
            </div>
            <div className="col">
              <i className="icon-plan-features02" />
              <p>Freedom to choose the best out of 4 covers for your pet covering Critical Illness, Specific Illness, OPD cover and Third Party Liability</p>
            </div>
            <div className="col">
              <i className="icon-plan-features03" />
              <p>Covers pets of 12 weeks to upto 6 years with renewal upto 10 years , Medical cover can be transferred to the new owner during cover period</p>
            </div>
            <div className="col">
              <i className="icon-plan-features04" />
              <p>Freedom to choose the four sum insured options meeting your budget and protection needs starting from ₹40,000 upto ₹1,50,000</p>
            </div>
            <div className="col">
              <i className="icon-plan-features05" />
              <p>Initial waiting period 30 days for illness and 7 days for injury. Pre-existing diseases covered after 4 years waiting. 20% co-pay on OPD claims . 2 years specific illness waiting and Third Party Liabilities upto opted sum insured</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
